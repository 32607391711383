import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Modal } from "react-bootstrap";
const VideoCommunity = () => {
  const owl_option = {
    nav: false,
    dots: false,
    dotsEach: false,
    loop: false,
    autoplay: false,
    navText: [
      //   "<i class='fas fa-angle-left'></i>",
      //   "<i class='fas fa-angle-right'></i>",
    ],
    responsive: {
      0: {
        items: 1.2,
        margin: 9,
        loop: true
      },
      361: {
        items: 1.2,
        margin: 9,
        loop: true
      },
      600: {
        items: 1.2,
        margin: 9,
        loop: true
      },
      700: {
        items: 3,
        margin: 9,
      },
      1000: {
        items: 4,
        margin: 20,
      },
      1200: {
        items: 4,
        margin: 20,
      },
    },
  };

  const [show, setShow] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setShow(true);
  };

  // Array of video URLs
  const videoUrls = [
    "https://www.youtube.com/embed/K_fzym4Jc18?si=yqvS_LLepvfsYdFA",
    "https://www.youtube.com/embed/MBX2yEP-MfQ?si=nxyDRpZJcMXVVd2e",
    "https://www.youtube.com/embed/9wuCramt0h8?si=nxVlm4c1L_GX6Gwf",
    "https://www.youtube.com/embed/bG-q-8EM_bU?si=mpuze-fxwopZAskU",
    // Add more video URLs as needed
  ];


  return (
    <>
      <section className="mainexplainer dop-community-video">
        <div className="custom-container">
          <div className="blogsinner justify-content-center">
            <div className="mainhead w-100">
              <h2 className="text-center">Community Videos</h2>
            </div>
          </div>
        </div>
        <div className="mainparentcards">
          <div class="owl_option">
            <OwlCarousel className="owl-theme" {...owl_option}>
            {videoUrls.map((videoUrl, index) => (
                <div key={index} onClick={() => handleShow(videoUrl)}>
                  <div className="mainimagessss">
                    <img
                      src={`/assets/community/communityvideo/${index + 1}.jpeg`}
                      alt="img"
                      className="img-fluid innerimage"
                    />
                    <span className="layer-bg"></span>
                    <img
                      src="/assets/community/communityvideo/playvideo-btn.svg"
                      alt="img"
                      className="img-fluid playvideo-btn"
                    />
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>

      <Modal className="contact-modal iframemodal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Community Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="560"
            height="315"
            src={selectedVideoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="youtubeiframe"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoCommunity;
