import React, { useEffect, useRef, useState } from "react";
import "./viedo.scss"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { gsap } from 'gsap';
import { Modal } from "react-bootstrap";


const Viedo = () => {

  const owl_option = {
    nav: false,
    dots: false,
    dotsEach: false,
    loop: false,
    autoplay: false,
    navText: [
      //   "<i class='fas fa-angle-left'></i>",
      //   "<i class='fas fa-angle-right'></i>",
    ],
    responsive: {
      0: {
        items: 1.2,
        margin: 9,
        loop: true
      },
      361: {
        items: 1.2,
        margin: 9,
        loop: true
      },
      600: {
        items: 1.2,
        margin: 9,
        loop: true
      },
      700: {
        items: 3,
        margin: 9,
      },
      1000: {
        items: 4,
        margin: 9,
      },
      1200: {
        items: 4,
        margin: 9,
      },
    },
  };

  const btnRef = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split('');

      ref.current.innerHTML = '';
      chars.forEach(char => {
        const charSpan = document.createElement('span');
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });

      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.03,
        duration: speed
      });

      gsap.to(ref.current.children, {
        color: '#fff',
        stagger: 0.03,
        duration: speed
      });
    };

    const btn = btnRef.current;

    const handleMouseEnter = () => {
      animateText(btnRef, 0.2);
    };

    const handleMouseLeave = () => {
      btnRef.current.innerHTML = btn.innerText;
    };

    btn.addEventListener('mouseenter', handleMouseEnter);
    btn.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      btn.removeEventListener('mouseenter', handleMouseEnter);
      btn.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  const [show, setShow] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setShow(true);
  };

  // Array of video URLs
  const videoUrls = [
    "https://www.youtube.com/embed/Y824iRhfnsU?si=yx6Q3vntwMjV1UTa",
    "https://www.youtube.com/embed/GJe9BWqaBsw?si=s1-Dn8oVcQzO06En",
    "https://www.youtube.com/embed/S3tS3Bnnt34?si=FWNGY6nVLKoG0WBR",
    "https://www.youtube.com/embed/3ClvwCUyv7Y?si=4Rbz7RWC3thD9ht0",
    // Add more video URLs as needed
  ];
  return (
    <>
      <section className="mainviedo">
        <div className="dopphasesheader">
          <p className="dopphasesheaderpara">
            <span>02.</span>
            <span>Video library</span>
          </p>
          <a href="https://www.youtube.com/@DataOwnershipProtocol" target="_blank" className="btn-launch">
            <h6 ref={btnRef}>Watch more</h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333"
                stroke="white"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        <div className="custom-container">
          <div className="blogsinner">
            <div className="mainhead">
              <h2>Video library</h2>
            </div>

          </div>
          <div className="dop-community-video">
            <div class="owl_option">
              <OwlCarousel className="owl-theme" {...owl_option}>
              {videoUrls.map((videoUrl, index) => (
                <div key={index} onClick={() => handleShow(videoUrl)}>
                  <div className="mainimagessss">
                    <img
                      src={`/assets/community/videolibrary/${index + 1}.png`}
                      alt="img"
                      className="img-fluid innerimage"
                      style={{height: "218px"}}
                    />
                    <span className="layer-bg"></span>
                    <img
                      src="/assets/community/communityvideo/playvideo-btn.svg"
                      alt="img"
                      className="img-fluid playvideo-btn"
                    />
                  </div>
                </div>
              ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      <Modal className="contact-modal iframemodal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Video library</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="560"
            height="315"
            src={selectedVideoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="youtubeiframe"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Viedo;
