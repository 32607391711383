import React, { useState } from 'react'
import './datacards.scss'
import { Accordion, Nav } from 'react-bootstrap';

const Datacards = () => {
    const [activeTab, setActiveTab] = useState('link-1');

    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    return (
        <>
            <section className="usecasesmain">
                <div className="usecasesheader">
                    <p className="usecasesheaderpara">
                        <span>02.</span>
                        <span>use cases</span>
                    </p>
                </div>
                <h4 className="usecaseshead">How can you benefit from DOP?</h4>
                <div className="usecasesinner">
                    <Nav variant="pills" activeKey={activeTab} onSelect={handleSelect}>
                        <Nav.Item>
                            <Nav.Link eventKey="link-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M11.2324 17.6078H20.8369V14.4078H24.036C24.033 11.2039 24.0391 8.0055 24.036 4.80162H20.8369V1.60779H11.2324V4.80779H8.03781C8.04085 8.01166 8.03476 11.2101 8.03781 14.4139H11.2324V17.6078ZM20.836 4.80779C20.8391 8.00962 20.833 11.206 20.836 14.4078H11.2378C11.2348 11.206 11.2409 8.0096 11.2378 4.80779H20.836Z" fill="white" />
                                    <path d="M27.2369 20.8078V17.6102H24.0369V20.8102H27.2369V27.2078H4.83693V20.8078H8.03693V17.6078H4.83693V20.8054H1.63693V27.2078V30.4078L30.4369 30.4102V20.8078H27.2369Z" fill="white" />
                                </svg>
                                for <span className="bold">Individuals</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M24.041 11.1806V7.98059H20.841V11.1806H11.241V7.98059H8.04099V11.1806H1.64187C1.63882 17.5842 1.64492 23.9769 1.64187 30.3806H30.4401C30.4432 23.9769 30.4371 17.5842 30.4401 11.1806H24.041ZM4.84187 27.1806C4.84222 22.9135 4.84152 18.6477 4.84187 14.3806H14.441V17.5806H17.641V14.3806H27.2401C27.2398 18.6477 27.2405 22.9135 27.2401 27.1806H4.84187Z" fill="white" />
                                    <path d="M11.241 4.78052H20.841V7.98052H11.241V4.78052Z" fill="white" />
                                </svg>
                                for <span className="bold">Businesses</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M24.4375 24.4376H22.5625V26.3126H24.4375V24.4376Z" fill="white" />
                                    <path d="M22.5625 24.4376H20.6875V26.3126H22.5625V24.4376Z" fill="white" />
                                    <path d="M11.3124 24.4376H9.43744V26.3126H11.3124V24.4376Z" fill="white" />
                                    <path d="M9.43746 24.4376H7.56247V26.3126H9.43746V24.4376Z" fill="white" />
                                    <path d="M26.3125 22.5626H24.4375V24.4376H26.3125V22.5626Z" fill="white" />
                                    <path d="M24.4375 22.5626H22.5625V24.4376H24.4375V22.5626Z" fill="white" />
                                    <path d="M9.43746 22.5626H7.56247V24.4376H9.43746V22.5626Z" fill="white" />
                                    <path d="M7.56246 22.5626H5.68747V24.4376H7.56246V22.5626Z" fill="white" />
                                    <path d="M26.3125 20.6875H24.4375V22.5625H26.3125V20.6875Z" fill="white" />
                                    <path d="M7.56246 20.6875H5.68747V22.5625H7.56246V20.6875Z" fill="white" />
                                    <path d="M5.68743 20.6875H3.81244V22.5625H5.68743V20.6875Z" fill="white" />
                                    <path d="M5.68743 18.8125H3.81244V20.6875H5.68743V18.8125Z" fill="white" />
                                    <path d="M5.68743 16.9376H3.81244V18.8126H5.68743V16.9376Z" fill="white" />
                                    <path d="M5.68743 15.0625H3.81244V16.9375H5.68743V15.0625Z" fill="white" />
                                    <path d="M5.68743 13.1875H3.81244V15.0625H5.68743V13.1875Z" fill="white" />
                                    <path d="M5.68743 11.3125H3.81244V13.1875H5.68743V11.3125Z" fill="white" />
                                    <path d="M5.68743 9.4375H3.81244V11.3125H5.68743V9.4375Z" fill="white" />
                                    <path d="M26.3125 7.5625H24.4375V9.43749H26.3125V7.5625Z" fill="white" />
                                    <path d="M7.56246 7.5625H5.68747V9.43749H7.56246V7.5625Z" fill="white" />
                                    <path d="M5.68743 7.5625H3.81244V9.43749H5.68743V7.5625Z" fill="white" />
                                    <path d="M26.3125 5.6875H24.4375V7.56249H26.3125V5.6875Z" fill="white" />
                                    <path d="M24.4375 5.6875H22.5625V7.56249H24.4375V5.6875Z" fill="white" />
                                    <path d="M22.5625 5.6875H20.6875V7.56249H22.5625V5.6875Z" fill="white" />
                                    <path d="M11.3124 5.6875H9.43744V7.56249H11.3124V5.6875Z" fill="white" />
                                    <path d="M9.43746 5.6875H7.56247V7.56249H9.43746V5.6875Z" fill="white" />
                                    <path d="M7.56246 5.6875H5.68747V7.56249H7.56246V5.6875Z" fill="white" />
                                    <path d="M22.5625 3.8125H20.6875V5.68749H22.5625V3.8125Z" fill="white" />
                                    <path d="M20.6875 3.8125H18.8125V5.68749H20.6875V3.8125Z" fill="white" />
                                    <path d="M18.8125 3.8125H16.9375V5.68749H18.8125V3.8125Z" fill="white" />
                                    <path d="M16.9375 3.8125H15.0625V5.68749H16.9375V3.8125Z" fill="white" />
                                    <path d="M15.0625 3.8125H13.1875V5.68749H15.0625V3.8125Z" fill="white" />
                                    <path d="M13.1875 3.8125H11.3125V5.68749H13.1875V3.8125Z" fill="white" />
                                    <path d="M11.3124 3.8125H9.43744V5.68749H11.3124V3.8125Z" fill="white" />
                                    <path d="M20.6875 28.1876H18.8125V30.0626H20.6875V28.1876Z" fill="white" />
                                    <path d="M18.8125 28.1876H16.9375V30.0626H18.8125V28.1876Z" fill="white" />
                                    <path d="M16.9375 28.1876H15.0625V30.0626H16.9375V28.1876Z" fill="white" />
                                    <path d="M15.0625 28.1876H13.1875V30.0626H15.0625V28.1876Z" fill="white" />
                                    <path d="M13.1875 28.1876H11.3125V30.0626H13.1875V28.1876Z" fill="white" />
                                    <path d="M22.5625 26.3125H20.6875V28.1875H22.5625V26.3125Z" fill="white" />
                                    <path d="M20.6875 26.3125H18.8125V28.1875H20.6875V26.3125Z" fill="white" />
                                    <path d="M13.1875 26.3125H11.3125V28.1875H13.1875V26.3125Z" fill="white" />
                                    <path d="M11.3124 26.3125H9.43744V28.1875H11.3124V26.3125Z" fill="white" />
                                    <path d="M28.1875 20.6875H26.3125V22.5625H28.1875V20.6875Z" fill="white" />
                                    <path d="M28.1875 18.8125H26.3125V20.6875H28.1875V18.8125Z" fill="white" />
                                    <path d="M28.1875 16.9376H26.3125V18.8126H28.1875V16.9376Z" fill="white" />
                                    <path d="M28.1875 15.0625H26.3125V16.9375H28.1875V15.0625Z" fill="white" />
                                    <path d="M28.1875 13.1875H26.3125V15.0625H28.1875V13.1875Z" fill="white" />
                                    <path d="M28.1875 11.3125H26.3125V13.1875H28.1875V11.3125Z" fill="white" />
                                    <path d="M28.1875 9.4375H26.3125V11.3125H28.1875V9.4375Z" fill="white" />
                                    <path d="M28.1875 7.5625H26.3125V9.43749H28.1875V7.5625Z" fill="white" />
                                    <path d="M18.8125 1.9375H16.9375V3.81249H18.8125V1.9375Z" fill="white" />
                                    <path d="M16.9375 1.9375H15.0625V3.81249H16.9375V1.9375Z" fill="white" />
                                    <path d="M15.0625 1.9375H13.1875V3.81249H15.0625V1.9375Z" fill="white" />
                                </svg>
                                As <span className="bold">a safe haven</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M24.1576 27.2168H22.1182V25.1774H24.1576V27.2168Z" fill="white" />
                                    <path d="M28.2365 19.0591H26.197V17.0196H28.2365V19.0591Z" fill="white" />
                                    <path d="M28.2365 17.0197H26.197V14.9802H28.2365V17.0197Z" fill="white" />
                                    <path d="M28.2365 14.9803H26.197V12.9409H28.2365V14.9803Z" fill="white" />
                                    <path d="M18.0394 19.0591H16V17.0196H18.0394V19.0591Z" fill="white" />
                                    <path d="M18.0394 17.0197H16V14.9802H18.0394V17.0197Z" fill="white" />
                                    <path d="M22.1183 17.0197H20.0788V14.9802H22.1183V17.0197Z" fill="white" />
                                    <path d="M18.0394 14.9803H16V12.9409H18.0394V14.9803Z" fill="white" />
                                    <path d="M26.1971 21.0985H24.1576V19.0591H26.1971V21.0985Z" fill="white" />
                                    <path d="M26.1971 12.9409H24.1576V10.9015H26.1971V12.9409Z" fill="white" />
                                    <path d="M24.1576 21.0985H22.1182V19.0591H24.1576V21.0985Z" fill="white" />
                                    <path d="M24.1576 12.9409H22.1182V10.9015H24.1576V12.9409Z" fill="white" />
                                    <path d="M22.1183 21.0985H20.0788V19.0591H22.1183V21.0985Z" fill="white" />
                                    <path d="M22.1183 12.9409H20.0788V10.9015H22.1183V12.9409Z" fill="white" />
                                    <path d="M20.0788 21.0985H18.0394V19.0591H20.0788V21.0985Z" fill="white" />
                                    <path d="M20.0788 12.9409H18.0394V10.9015H20.0788V12.9409Z" fill="white" />
                                    <path d="M18.0394 21.0985H16V19.0591H18.0394V21.0985Z" fill="white" />
                                    <path d="M18.0394 12.9409H16V10.9015H18.0394V12.9409Z" fill="white" />
                                    <path d="M7.84238 6.82263H5.80294V4.78319H7.84238V6.82263Z" fill="white" />
                                    <path d="M9.88174 6.82263H7.84231V4.78319H9.88174V6.82263Z" fill="white" />
                                    <path d="M11.9212 6.82263H9.88174V4.78319H11.9212V6.82263Z" fill="white" />
                                    <path d="M13.9606 6.82263H11.9211V4.78319H13.9606V6.82263Z" fill="white" />
                                    <path d="M13.9606 25.1774H16V27.2168H13.9606V25.1774Z" fill="white" />
                                    <path d="M11.9212 25.1774H13.9606V27.2168H11.9212V25.1774Z" fill="white" />
                                    <path d="M9.88174 25.1774H11.9212V27.2168H9.88174V25.1774Z" fill="white" />
                                    <path d="M7.84238 25.1774H9.88181V27.2168H7.84238V25.1774Z" fill="white" />
                                    <path d="M20.0788 25.1774H22.1182V27.2168H20.0788V25.1774Z" fill="white" />
                                    <path d="M24.1576 6.82263H22.1182V4.78319H24.1576V6.82263Z" fill="white" />
                                    <path d="M20.0788 4.7832H22.1182V6.82264H20.0788V4.7832Z" fill="white" />
                                    <path d="M18.0394 25.1774H20.0789V27.2168H18.0394V25.1774Z" fill="white" />
                                    <path d="M18.0394 4.7832H20.0789V6.82264H18.0394V4.7832Z" fill="white" />
                                    <path d="M16 25.1774H18.0394V27.2168H16V25.1774Z" fill="white" />
                                    <path d="M7.84238 27.2168H5.80294V25.1774H7.84238V27.2168Z" fill="white" />
                                    <path d="M5.80295 25.1774H3.76351V23.1379H5.80295V25.1774Z" fill="white" />
                                    <path d="M5.80295 23.1379H3.76351V21.0985H5.80295V23.1379Z" fill="white" />
                                    <path d="M26.1971 25.1774H24.1576V23.1379H26.1971V25.1774Z" fill="white" />
                                    <path d="M26.1971 23.1379H24.1576V21.0985H26.1971V23.1379Z" fill="white" />
                                    <path d="M5.80295 21.0985H3.76351V19.0591H5.80295V21.0985Z" fill="white" />
                                    <path d="M5.80295 19.0591H3.76351V17.0196H5.80295V19.0591Z" fill="white" />
                                    <path d="M5.80295 17.0197H3.76351V14.9802H5.80295V17.0197Z" fill="white" />
                                    <path d="M5.80295 14.9803H3.76351V12.9409H5.80295V14.9803Z" fill="white" />
                                    <path d="M5.80295 12.9409H3.76351V10.9015H5.80295V12.9409Z" fill="white" />
                                    <path d="M5.80295 10.9015H3.76351V8.86205H5.80295V10.9015Z" fill="white" />
                                    <path d="M5.80295 8.86218H3.76351V6.82275H5.80295V8.86218Z" fill="white" />
                                    <path d="M26.1971 10.9015H24.1576V8.86205H26.1971V10.9015Z" fill="white" />
                                    <path d="M26.1971 8.86218H24.1576V6.82275H26.1971V8.86218Z" fill="white" />
                                    <path d="M16 6.82263H13.9606V4.78319H16V6.82263Z" fill="white" />
                                    <path d="M18.0394 6.82263H16V4.78319H18.0394V6.82263Z" fill="white" />
                                </svg>
                                as <span className="bold">a Single Wallet</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div className="usecasesright">
                        {activeTab === 'link-1' && (
                            <>
                                <p className="usecasespara"><span className="bold">DOP</span> lets <span className="bold">you</span> control your personal and financial data on the blockchain, encrypt or decrypt your assets, and transact with confidence and regulatory compliance.</p>
                                <p className="usecaseslist"><span className="square"></span>Transact with selective transparency</p>
                                <p className="usecaseslist"><span className="square"></span>Showcase NFTs</p>
                                <p className="usecaseslist"><span className="square"></span>Explore Defi platforms without exposure to irrelevant parties</p>
                            </>
                        )}
                        {activeTab === 'link-2' && (
                            <>
                                <p className="usecasespara"><span className="bold">DOP</span> offers a powerful solution to safely navigate the complexities of blockchain technology. Unlocking new opportunities for business  growth. </p>
                                <p className="usecaseslist"><span className="square"></span>Integrate seamlessly with existing tools</p>
                                <p className="usecaseslist"><span className="square"></span>Block tainted funds</p>
                                <p className="usecaseslist"><span className="square"></span>Protect your competitive edge and build trust</p>
                            </>
                        )}
                        {activeTab === 'link-3' && (
                            <>
                                <p className="usecasespara">Smart security measures within <span className="bold">DOP</span> rapidly screen transactions to safeguard against illicit funds entering or exiting the ecosystem.</p>
                                <p className="usecaseslist"><span className="square"></span>Ensuring a safe haven in the blockchain landscape</p>
                            </>
                        )}
                        {activeTab === 'link-4' && (
                            <>
                                <p className="usecasespara"><span className="bold">DOP</span>’s technology eliminates the need for multiple wallets to maintain privacy, offering selective transparency within a <span className="bold">single, powerful wallet</span></p>
                                <p className="usecaseslist"><span className="square"></span>Embrace simplicity while retaining full control over your financial privacy.</p>
                            </>
                        )}
                    </div>
                </div>
                <div className="usecasesinnermbl d-none">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M11.2324 17.6078H20.8369V14.4078H24.036C24.033 11.2039 24.0391 8.0055 24.036 4.80162H20.8369V1.60779H11.2324V4.80779H8.03781C8.04085 8.01166 8.03476 11.2101 8.03781 14.4139H11.2324V17.6078ZM20.836 4.80779C20.8391 8.00962 20.833 11.206 20.836 14.4078H11.2378C11.2348 11.206 11.2409 8.0096 11.2378 4.80779H20.836Z" fill="white" />
                                    <path d="M27.2369 20.8078V17.6102H24.0369V20.8102H27.2369V27.2078H4.83693V20.8078H8.03693V17.6078H4.83693V20.8054H1.63693V27.2078V30.4078L30.4369 30.4102V20.8078H27.2369Z" fill="white" />
                                </svg>
                                for <span className="bold"> Individuals</span>
                            </Accordion.Header>
                            <Accordion.Body>
                            <p className="usecasespara"><span className="bold">DOP</span> lets <span className="bold">you</span> control your personal and financial data on the blockchain, encrypt or decrypt your assets, and transact with confidence and regulatory compliance.</p>
                                <p className="usecaseslist"><span className="square"></span>Transact with selective transparency</p>
                                <p className="usecaseslist"><span className="square"></span>Showcase NFTs</p>
                                <p className="usecaseslist"><span className="square"></span>Explore Defi platforms without exposure to irrelevant parties</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M24.041 11.1806V7.98059H20.841V11.1806H11.241V7.98059H8.04099V11.1806H1.64187C1.63882 17.5842 1.64492 23.9769 1.64187 30.3806H30.4401C30.4432 23.9769 30.4371 17.5842 30.4401 11.1806H24.041ZM4.84187 27.1806C4.84222 22.9135 4.84152 18.6477 4.84187 14.3806H14.441V17.5806H17.641V14.3806H27.2401C27.2398 18.6477 27.2405 22.9135 27.2401 27.1806H4.84187Z" fill="white" />
                                    <path d="M11.241 4.78052H20.841V7.98052H11.241V4.78052Z" fill="white" />
                                </svg>
                                for <span className="bold"> Businesses</span>
                            </Accordion.Header>
                            <Accordion.Body>
                            <p className="usecasespara"><span className="bold">DOP</span> offers a powerful solution to safely navigate the complexities of blockchain technology. Unlocking new opportunities for business  growth. </p>
                                <p className="usecaseslist"><span className="square"></span>Integrate seamlessly with existing tools</p>
                                <p className="usecaseslist"><span className="square"></span>Block tainted funds</p>
                                <p className="usecaseslist"><span className="square"></span>Protect your competitive edge and build trust</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M24.4375 24.4376H22.5625V26.3126H24.4375V24.4376Z" fill="white" />
                                    <path d="M22.5625 24.4376H20.6875V26.3126H22.5625V24.4376Z" fill="white" />
                                    <path d="M11.3124 24.4376H9.43744V26.3126H11.3124V24.4376Z" fill="white" />
                                    <path d="M9.43746 24.4376H7.56247V26.3126H9.43746V24.4376Z" fill="white" />
                                    <path d="M26.3125 22.5626H24.4375V24.4376H26.3125V22.5626Z" fill="white" />
                                    <path d="M24.4375 22.5626H22.5625V24.4376H24.4375V22.5626Z" fill="white" />
                                    <path d="M9.43746 22.5626H7.56247V24.4376H9.43746V22.5626Z" fill="white" />
                                    <path d="M7.56246 22.5626H5.68747V24.4376H7.56246V22.5626Z" fill="white" />
                                    <path d="M26.3125 20.6875H24.4375V22.5625H26.3125V20.6875Z" fill="white" />
                                    <path d="M7.56246 20.6875H5.68747V22.5625H7.56246V20.6875Z" fill="white" />
                                    <path d="M5.68743 20.6875H3.81244V22.5625H5.68743V20.6875Z" fill="white" />
                                    <path d="M5.68743 18.8125H3.81244V20.6875H5.68743V18.8125Z" fill="white" />
                                    <path d="M5.68743 16.9376H3.81244V18.8126H5.68743V16.9376Z" fill="white" />
                                    <path d="M5.68743 15.0625H3.81244V16.9375H5.68743V15.0625Z" fill="white" />
                                    <path d="M5.68743 13.1875H3.81244V15.0625H5.68743V13.1875Z" fill="white" />
                                    <path d="M5.68743 11.3125H3.81244V13.1875H5.68743V11.3125Z" fill="white" />
                                    <path d="M5.68743 9.4375H3.81244V11.3125H5.68743V9.4375Z" fill="white" />
                                    <path d="M26.3125 7.5625H24.4375V9.43749H26.3125V7.5625Z" fill="white" />
                                    <path d="M7.56246 7.5625H5.68747V9.43749H7.56246V7.5625Z" fill="white" />
                                    <path d="M5.68743 7.5625H3.81244V9.43749H5.68743V7.5625Z" fill="white" />
                                    <path d="M26.3125 5.6875H24.4375V7.56249H26.3125V5.6875Z" fill="white" />
                                    <path d="M24.4375 5.6875H22.5625V7.56249H24.4375V5.6875Z" fill="white" />
                                    <path d="M22.5625 5.6875H20.6875V7.56249H22.5625V5.6875Z" fill="white" />
                                    <path d="M11.3124 5.6875H9.43744V7.56249H11.3124V5.6875Z" fill="white" />
                                    <path d="M9.43746 5.6875H7.56247V7.56249H9.43746V5.6875Z" fill="white" />
                                    <path d="M7.56246 5.6875H5.68747V7.56249H7.56246V5.6875Z" fill="white" />
                                    <path d="M22.5625 3.8125H20.6875V5.68749H22.5625V3.8125Z" fill="white" />
                                    <path d="M20.6875 3.8125H18.8125V5.68749H20.6875V3.8125Z" fill="white" />
                                    <path d="M18.8125 3.8125H16.9375V5.68749H18.8125V3.8125Z" fill="white" />
                                    <path d="M16.9375 3.8125H15.0625V5.68749H16.9375V3.8125Z" fill="white" />
                                    <path d="M15.0625 3.8125H13.1875V5.68749H15.0625V3.8125Z" fill="white" />
                                    <path d="M13.1875 3.8125H11.3125V5.68749H13.1875V3.8125Z" fill="white" />
                                    <path d="M11.3124 3.8125H9.43744V5.68749H11.3124V3.8125Z" fill="white" />
                                    <path d="M20.6875 28.1876H18.8125V30.0626H20.6875V28.1876Z" fill="white" />
                                    <path d="M18.8125 28.1876H16.9375V30.0626H18.8125V28.1876Z" fill="white" />
                                    <path d="M16.9375 28.1876H15.0625V30.0626H16.9375V28.1876Z" fill="white" />
                                    <path d="M15.0625 28.1876H13.1875V30.0626H15.0625V28.1876Z" fill="white" />
                                    <path d="M13.1875 28.1876H11.3125V30.0626H13.1875V28.1876Z" fill="white" />
                                    <path d="M22.5625 26.3125H20.6875V28.1875H22.5625V26.3125Z" fill="white" />
                                    <path d="M20.6875 26.3125H18.8125V28.1875H20.6875V26.3125Z" fill="white" />
                                    <path d="M13.1875 26.3125H11.3125V28.1875H13.1875V26.3125Z" fill="white" />
                                    <path d="M11.3124 26.3125H9.43744V28.1875H11.3124V26.3125Z" fill="white" />
                                    <path d="M28.1875 20.6875H26.3125V22.5625H28.1875V20.6875Z" fill="white" />
                                    <path d="M28.1875 18.8125H26.3125V20.6875H28.1875V18.8125Z" fill="white" />
                                    <path d="M28.1875 16.9376H26.3125V18.8126H28.1875V16.9376Z" fill="white" />
                                    <path d="M28.1875 15.0625H26.3125V16.9375H28.1875V15.0625Z" fill="white" />
                                    <path d="M28.1875 13.1875H26.3125V15.0625H28.1875V13.1875Z" fill="white" />
                                    <path d="M28.1875 11.3125H26.3125V13.1875H28.1875V11.3125Z" fill="white" />
                                    <path d="M28.1875 9.4375H26.3125V11.3125H28.1875V9.4375Z" fill="white" />
                                    <path d="M28.1875 7.5625H26.3125V9.43749H28.1875V7.5625Z" fill="white" />
                                    <path d="M18.8125 1.9375H16.9375V3.81249H18.8125V1.9375Z" fill="white" />
                                    <path d="M16.9375 1.9375H15.0625V3.81249H16.9375V1.9375Z" fill="white" />
                                    <path d="M15.0625 1.9375H13.1875V3.81249H15.0625V1.9375Z" fill="white" />
                                </svg>
                                As <span className="bold"> a safe haven</span>
                            </Accordion.Header>
                            <Accordion.Body>
                            <p className="usecasespara">Smart security measures within <span className="bold">DOP</span> rapidly screen transactions to safeguard against illicit funds entering or exiting the ecosystem.</p>
                                <p className="usecaseslist"><span className="square"></span>Ensuring a safe haven in the blockchain landscape</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M24.1576 27.2168H22.1182V25.1774H24.1576V27.2168Z" fill="white" />
                                    <path d="M28.2365 19.0591H26.197V17.0196H28.2365V19.0591Z" fill="white" />
                                    <path d="M28.2365 17.0197H26.197V14.9802H28.2365V17.0197Z" fill="white" />
                                    <path d="M28.2365 14.9803H26.197V12.9409H28.2365V14.9803Z" fill="white" />
                                    <path d="M18.0394 19.0591H16V17.0196H18.0394V19.0591Z" fill="white" />
                                    <path d="M18.0394 17.0197H16V14.9802H18.0394V17.0197Z" fill="white" />
                                    <path d="M22.1183 17.0197H20.0788V14.9802H22.1183V17.0197Z" fill="white" />
                                    <path d="M18.0394 14.9803H16V12.9409H18.0394V14.9803Z" fill="white" />
                                    <path d="M26.1971 21.0985H24.1576V19.0591H26.1971V21.0985Z" fill="white" />
                                    <path d="M26.1971 12.9409H24.1576V10.9015H26.1971V12.9409Z" fill="white" />
                                    <path d="M24.1576 21.0985H22.1182V19.0591H24.1576V21.0985Z" fill="white" />
                                    <path d="M24.1576 12.9409H22.1182V10.9015H24.1576V12.9409Z" fill="white" />
                                    <path d="M22.1183 21.0985H20.0788V19.0591H22.1183V21.0985Z" fill="white" />
                                    <path d="M22.1183 12.9409H20.0788V10.9015H22.1183V12.9409Z" fill="white" />
                                    <path d="M20.0788 21.0985H18.0394V19.0591H20.0788V21.0985Z" fill="white" />
                                    <path d="M20.0788 12.9409H18.0394V10.9015H20.0788V12.9409Z" fill="white" />
                                    <path d="M18.0394 21.0985H16V19.0591H18.0394V21.0985Z" fill="white" />
                                    <path d="M18.0394 12.9409H16V10.9015H18.0394V12.9409Z" fill="white" />
                                    <path d="M7.84238 6.82263H5.80294V4.78319H7.84238V6.82263Z" fill="white" />
                                    <path d="M9.88174 6.82263H7.84231V4.78319H9.88174V6.82263Z" fill="white" />
                                    <path d="M11.9212 6.82263H9.88174V4.78319H11.9212V6.82263Z" fill="white" />
                                    <path d="M13.9606 6.82263H11.9211V4.78319H13.9606V6.82263Z" fill="white" />
                                    <path d="M13.9606 25.1774H16V27.2168H13.9606V25.1774Z" fill="white" />
                                    <path d="M11.9212 25.1774H13.9606V27.2168H11.9212V25.1774Z" fill="white" />
                                    <path d="M9.88174 25.1774H11.9212V27.2168H9.88174V25.1774Z" fill="white" />
                                    <path d="M7.84238 25.1774H9.88181V27.2168H7.84238V25.1774Z" fill="white" />
                                    <path d="M20.0788 25.1774H22.1182V27.2168H20.0788V25.1774Z" fill="white" />
                                    <path d="M24.1576 6.82263H22.1182V4.78319H24.1576V6.82263Z" fill="white" />
                                    <path d="M20.0788 4.7832H22.1182V6.82264H20.0788V4.7832Z" fill="white" />
                                    <path d="M18.0394 25.1774H20.0789V27.2168H18.0394V25.1774Z" fill="white" />
                                    <path d="M18.0394 4.7832H20.0789V6.82264H18.0394V4.7832Z" fill="white" />
                                    <path d="M16 25.1774H18.0394V27.2168H16V25.1774Z" fill="white" />
                                    <path d="M7.84238 27.2168H5.80294V25.1774H7.84238V27.2168Z" fill="white" />
                                    <path d="M5.80295 25.1774H3.76351V23.1379H5.80295V25.1774Z" fill="white" />
                                    <path d="M5.80295 23.1379H3.76351V21.0985H5.80295V23.1379Z" fill="white" />
                                    <path d="M26.1971 25.1774H24.1576V23.1379H26.1971V25.1774Z" fill="white" />
                                    <path d="M26.1971 23.1379H24.1576V21.0985H26.1971V23.1379Z" fill="white" />
                                    <path d="M5.80295 21.0985H3.76351V19.0591H5.80295V21.0985Z" fill="white" />
                                    <path d="M5.80295 19.0591H3.76351V17.0196H5.80295V19.0591Z" fill="white" />
                                    <path d="M5.80295 17.0197H3.76351V14.9802H5.80295V17.0197Z" fill="white" />
                                    <path d="M5.80295 14.9803H3.76351V12.9409H5.80295V14.9803Z" fill="white" />
                                    <path d="M5.80295 12.9409H3.76351V10.9015H5.80295V12.9409Z" fill="white" />
                                    <path d="M5.80295 10.9015H3.76351V8.86205H5.80295V10.9015Z" fill="white" />
                                    <path d="M5.80295 8.86218H3.76351V6.82275H5.80295V8.86218Z" fill="white" />
                                    <path d="M26.1971 10.9015H24.1576V8.86205H26.1971V10.9015Z" fill="white" />
                                    <path d="M26.1971 8.86218H24.1576V6.82275H26.1971V8.86218Z" fill="white" />
                                    <path d="M16 6.82263H13.9606V4.78319H16V6.82263Z" fill="white" />
                                    <path d="M18.0394 6.82263H16V4.78319H18.0394V6.82263Z" fill="white" />
                                </svg>
                                as <span className="bold"> a Single Wallet</span>
                            </Accordion.Header>
                            <Accordion.Body>
                            <p className="usecasespara"><span className="bold">DOP</span>’s technology eliminates the need for multiple wallets to maintain privacy, offering selective transparency within a <span className="bold">single, powerful wallet</span></p>
                                <p className="usecaseslist"><span className="square"></span>Embrace simplicity while retaining full control over your financial privacy.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>
        </>
    )
}

export default Datacards