import React, { useEffect, useRef } from "react";
import { gsap } from 'gsap';
import Visionjoin from "../Vision/Visionjoin";
import Navbar from "../landing/header/Navbar";
import Footer from "../landing/footer/Footer";
import ComingSoon from "./ComingSoon";
import Ambassadorbanner from "./Ambassadorbanner";
function Ambassador() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);

  useEffect(() => {
    const isLargeScreen = window.matchMedia("(min-width: 1500px)").matches;
    const isMobileScreen = window.matchMedia("(max-width: 600px)").matches;

    gsap.to(firstSectionRef.current, {
        scrollTrigger: {
            trigger: secondSectionRef.current,
            start: isMobileScreen ? 'top 40%' : (isLargeScreen ? 'top 35%' : 'top 40%'),
            end: isMobileScreen ? 'bottom top' : (isLargeScreen ? 'bottom top' : 'bottom top'),
            scrub: true,
        },
        filter: 'blur(40px)',
    });
}, []);

  
  return (
    <>
      <Navbar />
      <Ambassadorbanner firstSectionRef={firstSectionRef}  />
      <ComingSoon secondSectionRef={secondSectionRef} />
      <Visionjoin />
      <Footer />
    </>
  );
}
export default Ambassador;