import '../../App.scss';
import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar';
import Transparency from './Transparency/Transparency.js';
import Partners from './Partners/Partners.js';
import Stats from './Stats/Stats.js';

import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Footer from './footer/Footer.js';


gsap.registerPlugin(ScrollTrigger);



function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);


  useEffect(() => {
    gsap.to(firstSectionRef.current, {
      scrollTrigger: {
        trigger: secondSectionRef.current,
        start: 'top 90%', 
        end: 'bottom top',
        scrub: true,
      },
      filter: 'blur(40px)',
    });
  }, []);


  return (
    <>
      <Navbar />
      <Banner firstSectionRef={firstSectionRef} secondSectionRef={secondSectionRef} />
      <Transparency secondSectionRef={secondSectionRef} />
      <Partners />
      <Stats />
      <Footer />
    </>
  );
}

export default Landing;