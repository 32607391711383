import React, { useEffect, useRef } from 'react'
import "./stats.scss"
import gsap from "gsap";

import { ScrollTrigger } from 'gsap/ScrollTrigger';

import lottie from 'lottie-web';


gsap.registerPlugin(ScrollTrigger);

const Stats = () => {
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);
    const textRef3 = useRef(null);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        const isMobile = mediaQuery.matches;

        animateText(textRef1, '#6C6C6C', 0.1, isMobile);
        animateText(textRef2, '#fff', 0.1, isMobile);
        animateText(textRef3, '#9A9A9A', 0.03, isMobile);


        const handleMediaChange = (e) => {
            const isMobile = e.matches;
            animateText(textRef1, '#6C6C6C', 0.1, isMobile);
            animateText(textRef2, '#fff', 0.1, isMobile);
            animateText(textRef3, '#9A9A9A', 0.03, isMobile);

        };

        mediaQuery.addListener(handleMediaChange);

        return () => {
            mediaQuery.removeListener(handleMediaChange);
        };
    }, []);

    const animateText = (ref, color, stagger, isMobile) => {
        const chars = ref.current.innerText.split('');
        ref.current.innerHTML = '';

        chars.forEach((char, i) => {
            const charSpan = document.createElement('span');
            charSpan.innerText = char;
            ref.current.appendChild(charSpan);
        });

        gsap.from(ref.current.children, {
            y: 100,
            opacity: 0.2,
            stagger: stagger || 0.1,
            scrollTrigger: {
                trigger: ref.current,
                start: isMobile ? 'top 150%' : 'top 100%',
                end: isMobile ? 'bottom 20%' : 'bottom 80%',
                scrub: true
            }
        });

        gsap.to(ref.current.children, {
            color: color || '#6C6C6C',
            stagger: stagger || 0.1,
            scrollTrigger: {
                trigger: ref.current,
                start: isMobile ? 'top 150%' : 'top 100%',
                end: isMobile ? 'bottom 20%' : 'bottom 80%',
                scrub: true
            }
        });
    };



    const containerRef = useRef(null);

    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: containerRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animation-2.json',
        });

        return () => {
            animation.destroy();
        };
    }, []);


    return (
        <>
            <section className="stats-section">
                {/* <img src="\assets\landing\stats-bg.svg" alt="img" className='img-fluid stats-bg' /> */}
                <div className="testimonial-lottie-1 is-margin-bottom stats-bg" ref={containerRef}></div>
                <div className="top-left-text">
                    <p ref={textRef1}>03. Stats</p>
                </div>
                <div className="custom-container">
                    <div className="main-heading">
                        <h6 ref={textRef2}>A Market Ripe for Revolution</h6>
                        <p ref={textRef3}>Interoperability and seamless integration with existing wallets and dApps are some of the major staples in DOP's architecture. We're not offering a niche solution — we're democratizing data ownership for the mainstream assets you already cherish.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Stats
