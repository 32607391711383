import React, { useEffect, useState } from "react";
import "./blog.scss";
import Navbar from "../landing/header/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
// import Blogdetail from "./Blogdetail";/

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [press, setallpress] = useState();
  const [presstop, setallpresstop] = useState();
  const [loading, setLoading] = useState(true);
  const [opens, setOpens] = useState(false);
  const Token = localStorage.getItem("AccessToken");
  const AllPress = () => {
    setOpens(true);
    setLoading(true);
    let limit = 1000;
    axios
      .get(`https://blogs.dop.org/wp-json/wp/v2/posts?per_page=100`)
      .then((response) => {
        setOpens(false);
        // var arr = response?.data;
        // var theRemovedElement = arr.shift();
        setallpress(response?.data);
        setLoading(false);
        // setallpresstop(theRemovedElement)
      })
      .catch((err) => {
        setOpens(false);
        setLoading(false);
        toast.warning("Error", {
          position: "top-right",
          autoClose: 3000,
        });
        return false;
      });
  };
  useEffect(() => {
    AllPress();
  }, []);

  const ALLPRESS = press?.slice(1)?.map((elem, index) => {
    const str = elem?.title?.rendered?.replace(/\s/g, "-");
    const str2 = str?.toLowerCase();
    // console.log("we get values",str)
    const originalText = elem?.title.rendered;
    const correctedText = originalText?.replace(/&#8217;/g, "'");
    const correctedText2 = correctedText?.replace(/&#8216;/g, "'");
    const correctedText3 = correctedText2?.replace(/&#8211;/g, "-");
    const correctedText4 = correctedText3?.replace(/&#038;/g, "&");
    const correctedText5 = correctedText4?.replace(/&#8221;/g, '"');

    const orignalurl = str2;
    const correcturl = orignalurl?.replace(/&#8217;/g, "'");
    const correcturl1 = correcturl?.replace(/&#8216;/g, "'");
    const correcturl2 = correcturl1?.replace(/&#8211;/g, "-");
    const correcturl3 = correcturl2?.replace(/&#038;/g, "&");
    const correcturl4 = correcturl3?.replace(/&#8221;/g, '"');

    return (
      <>
        <div className="single-card">
          <div className="main-img">
            <img
              src={elem?.uagb_featured_image_src?.full[0]}
              alt="img"
              className="img-fluid"
            />
          </div>
          <Link to={`/blog/${elem.slug}`}>
            <div className="bottom-text">
              <div className="left">
                <div className="set-ellipses">
                  <h6>{correctedText5 ? correctedText5 : ""}</h6>
                </div>
                <p>
                  {" "}
                  {elem?.date?.slice(0, 10).split("-").reverse().join("-")}
                </p>
              </div>
              <div className="right">
                <a className="btn-readmore">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="10"
                    viewBox="0 0 8 10"
                    fill="none"
                  >
                    <path
                      d="M0.990235 0.98999L5.99023 4.98999L0.990235 8.98999"
                      stroke="black"
                      stroke-width="2"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </Link>
        </div>
      </>
    );
  });

  var str = presstop?.title?.rendered?.replace(/\s/g, "-");
  var str2 = str?.toLowerCase();
  // console.log("sddsdssd", str2)
  // const toppress = presstop?.map((elem, index) => {
  //   const str = elem?.title.rendered.replace(/\s/g, '-');
  //   const str2 = str.toLowerCase()
  //   // console.log("we get values",str)
  //   return (
  //     <>

  //     </>
  //   );
  // });

  const { t } = useTranslation();
  const FirstPress = press?.map((elem, index) => {
    // Check if index is 0
    if (index === 0) {
      const str = elem?.title?.rendered?.replace(/\s/g, "-");
      const str2 = str?.toLowerCase();
      // console.log("we get values",str)
      const originalText = elem?.title.rendered;
      const correctedText = originalText?.replace(/&#8217;/g, "'");
      const correctedText2 = correctedText?.replace(/&#8216;/g, "'");
      const correctedText3 = correctedText2?.replace(/&#8211;/g, "-");
      const correctedText4 = correctedText3?.replace(/&#038;/g, "&");
      const correctedText5 = correctedText4?.replace(/&#8221;/g, '"');

      const orignalurl = str2;
      const correcturl = orignalurl?.replace(/&#8217;/g, "'");
      const correcturl1 = correcturl?.replace(/&#8216;/g, "'");
      const correcturl2 = correcturl1?.replace(/&#8211;/g, "-");
      const correcturl3 = correcturl2?.replace(/&#038;/g, "&");
      const correcturl4 = correcturl3?.replace(/&#8221;/g, '"');

      return (
        <>
          <Link to={`/blog/${elem.slug}`}>
            <div className="featured-new-content">
              <div className="featured-img common-width">
                <img
                  src={elem?.uagb_featured_image_src?.full[0]}
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="right-content common-width">
                <h6>Featured</h6>
                <h4>{correctedText5 ? correctedText5 : ""}</h4>
                <p>{elem?.date?.slice(0, 10).split("-").reverse().join("-")}</p>
              </div>
            </div>
          </Link>
        </>
      );
    } else {
      return null; // If index is not 0, return null to skip rendering
    }
  });

  return (
    <>
      <Navbar />
      <section className="blog-section">
        <div className="custom-container">
          <div className="main-heading">
            <h6>Blog</h6>
          </div>
          {FirstPress}

          <div className="bottom-blogs">
            {/* <div className="single-card">
                            <div className="main-img">
                                <img src={elem?.uagb_featured_image_src.full[0]} alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-text">
                                <div className="left">
                                    <h6>{elem?.uagb_author_info
                                        .display_name}</h6>
                                    <p> {elem?.modified
                                        ?.slice(0, 10)
                                        .split('-')
                                        .reverse()
                                        .join('-')}</p>
                                </div>
                                <div className="right">
                                    <Link to={`/blogdetail/` + str2 + `?id=${elem.id}`} className='btn-readmore'>Read more</Link>
                                </div>
                            </div>
                        </div>
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\videos\img1.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-text">
                                <div className="left">
                                    <h6>INTRODUCING DATA OWNERSHIP PROTOCOL</h6>
                                    <p>8-11-2023</p>
                                </div>
                                <div className="right">
                                    <Link to="/blogdetail" className='btn-readmore'>Read more</Link>
                                </div>
                            </div>
                        </div>
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\videos\img1.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-text">
                                <div className="left">
                                    <h6>INTRODUCING DATA OWNERSHIP PROTOCOL</h6>
                                    <p>8-11-2023</p>
                                </div>
                                <div className="right">
                                    <Link to="/blogdetail" className='btn-readmore'>Read more</Link>
                                </div>
                            </div>
                        </div>
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\videos\img1.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-text">
                                <div className="left">
                                    <h6>INTRODUCING DATA OWNERSHIP PROTOCOL</h6>
                                    <p>8-11-2023</p>
                                </div>
                                <div className="right">
                                    <Link to="/blogdetail" className='btn-readmore'>Read more</Link>
                                </div>
                            </div>
                        </div>
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\videos\img1.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-text">
                                <div className="left">
                                    <h6>INTRODUCING DATA OWNERSHIP PROTOCOL</h6>
                                    <p>8-11-2023</p>
                                </div>
                                <div className="right">
                                    <Link to="/blogdetail" className='btn-readmore'>Read more</Link>
                                </div>
                            </div>
                        </div>
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\videos\img1.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-text">
                                <div className="left">
                                    <h6>INTRODUCING DATA OWNERSHIP PROTOCOL</h6>
                                    <p>8-11-2023</p>
                                </div>
                                <div className="right">
                                    <Link to="/blogdetail" className='btn-readmore'>Read more</Link>
                                </div>
                            </div>
                        </div> */}
{/* 
            <div className="set-loading-mid">
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p style={{ color: "#fff" }}>Loading...</p>
            </div> */}

            {loading ? (
              <div className="set-loading-mid">
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p style={{ color: "#fff" }}>Loading...</p>
              </div>
            ) : press?.length > 0 ? (
              ALLPRESS
            ) : (
              <h6 style={{ color: "#fff", textAlign: "center", width: "100%" }}>
                No Data Here Right Now
              </h6>
            )}

            {/* {ALLPRESS ? ALLPRESS : <h6 style={{color: "#fff", textAlign: "center", width: "100%"}}>No Data Here Right Now</h6>} */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
