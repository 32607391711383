import React from "react";
import "./acdemy.scss";
const Academy = () => {
  return (
    <>
      <section className="mainacademy">
        <div className="dopphasesheader">
          <p className="dopphasesheaderpara">
            <span>03.</span>
            <span>DOP Academy</span>
          </p>
        </div>
        <div className="custom-container">
          <div className="parentheadssss">
            <h2>DOP Academy</h2>
            <p>Learn the basics of DOP with DOPmaster</p>
          </div>
          <div className="academycardparent">
            <div className="academycards">
            <a href="https://twitter.com/dop_org/status/1753817938438545742/video/1" target="_blank">
              <div className="cardssimage">
                <img
                  src="\assets\landing\education.png"
                  alt="img"
                  className="img-fluid innerimagess"
                />
              </div>
              </a>
              <a href="https://twitter.com/dop_org/status/1753817938438545742/video/1" target="_blank" className="set-margin-top">
                View Tweet{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M13.6988 16.9474L19.0251 11.5L13.6988 6.05261M5.70929 16.9474L11.0356 11.5L5.70929 6.05261"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
            <div className="academycards">
            <a href="https://twitter.com/dop_org/status/1756374419050475982/photo/1" target="_blank" >
              <div className="cardssimage">
                <img
                  src="\assets\landing\coolaboration.png"
                  alt="img"
                  className="img-fluid innerimagess"
                />
              </div>
              </a>
              <a href="https://twitter.com/dop_org/status/1756374419050475982/photo/1" target="_blank" className="set-margin-top">
                View Tweet{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M13.6988 16.9474L19.0251 11.5L13.6988 6.05261M5.70929 16.9474L11.0356 11.5L5.70929 6.05261"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
            <div className="academycards">
            <a href="https://twitter.com/dop_org/status/1758927781977538582/photo/1" target="_blank">
              <div className="cardssimage">
                <img
                  src="\assets\landing\integration.png"
                  alt="img"
                  className="img-fluid innerimagess"
                />
              </div>
              </a>
              <a href="https://twitter.com/dop_org/status/1758927781977538582/photo/1" target="_blank" className="set-margin-top">
                View Tweet{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M13.6988 16.9474L19.0251 11.5L13.6988 6.05261M5.70929 16.9474L11.0356 11.5L5.70929 6.05261"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
            <div className="academycards">
            <a href="https://twitter.com/dop_org/status/1761458522334675148/photo/1" target="_blank">
              <div className="cardssimage">
                <img
                  src="\assets\landing\safehaven.png"
                  alt="img"
                  className="img-fluid innerimagess"
                />
              </div>
              </a>
              <a href="https://twitter.com/dop_org/status/1761458522334675148/photo/1" target="_blank" className="set-margin-top">
                View Tweet{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M13.6988 16.9474L19.0251 11.5L13.6988 6.05261M5.70929 16.9474L11.0356 11.5L5.70929 6.05261"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="academycardparent mmmmm">
            <div className="academycards">
            <a href="https://twitter.com/dop_org/status/1764017931824824672/photo/1" target="_blank">
              <div className="cardssimage">
                <img
                  src="\assets\landing\userexperience.png"
                  alt="img"
                  className="img-fluid innerimagess"
                />
              </div>
              </a>
              <a href="https://twitter.com/dop_org/status/1764017931824824672/photo/1" target="_blank" className="set-margin-top">
                View Tweet{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M13.6988 16.9474L19.0251 11.5L13.6988 6.05261M5.70929 16.9474L11.0356 11.5L5.70929 6.05261"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
            <div className="academycards">
            <a href="https://twitter.com/dop_org/status/1766518743293677924/photo/1" target="_blank">
              <div className="cardssimage">
                <img
                  src="\assets\landing\proregulation.png"
                  alt="img"
                  className="img-fluid innerimagess"
                />
              </div>
              </a>
              <a href="https://twitter.com/dop_org/status/1766518743293677924/photo/1" target="_blank" className="set-margin-top">
                View Tweet{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M13.6988 16.9474L19.0251 11.5L13.6988 6.05261M5.70929 16.9474L11.0356 11.5L5.70929 6.05261"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
            <div className="academycards">
            <a href="https://twitter.com/dop_org/status/1776691964022345917/photo/1" target="_blank">
              <div className="cardssimage">
                <img
                  src="\assets\landing\technology.png"
                  alt="img"
                  className="img-fluid innerimagess"
                />
              </div>
              </a>
              <a href="https://twitter.com/dop_org/status/1776691964022345917/photo/1" target="_blank" className="set-margin-top">
                View Tweet{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M13.6988 16.9474L19.0251 11.5L13.6988 6.05261M5.70929 16.9474L11.0356 11.5L5.70929 6.05261"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
        
          </div>
        </div>
      </section>
    </>
  );
};

export default Academy;
