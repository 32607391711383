import React from 'react';
import './techcards.scss'

const Techcards = ({secondSectionRef}) => {
    return (
        <>
            <section className="techcards" ref={secondSectionRef}>
                <div className="techcardsheader">
                    <p className="techcardsheaderpara">
                        <span>01.</span>
                        <span>the Tech</span>
                    </p>
                </div>
                <div className="custom-container">
                    <div className="techcardstop">
                        <p className="techcardstopleft">By harnessing advanced technologies like zk-SNARKS and ECDSA, we give users the power to finely tailor the details they disclose about their assets and transactions while maintaining smooth compatibility with Ethereum dApps and liquidity.</p>
                        {/* <p className="techcardstopright">With a collective wealth of experience spanning various aspects of the blockchain industry, our team is committed to driving DOP's mission forward.</p> */}
                    </div>
                    <div className="techcardsmain">
                        <div className="techcard">
                            <img src="\assets\tech\cardone.svg" alt="techcardimg" className="techcardimg" />
                            <h6 className="techcardhead">External pool for token management</h6>
                            <p className="techcardpara">Tokens are managed in an external pool, facilitating transfers from external wallets and removing the information from the public.</p>
                        </div>
                        <div className="techcard">
                            <img src="\assets\tech\cardtwo.svg" alt="techcardimg" className="techcardimg" />
                            <h6 className="techcardhead">Zero-Knowledge Proofs (zk-proofs)</h6>
                            <p className="techcardpara">Verify token ownership and transactions without revealing sensitive information.</p>
                        </div>
                        <div className="techcard">
                            <img src="\assets\tech\cardthree.svg" alt="techcardimg" className="techcardimg" />
                            <h6 className="techcardhead">Indirect Wallet-to-Wallet Transactions</h6>
                            <p className="techcardpara">Users interact with the protocol for wallet-to-wallet transfers, ensuring confidentiality and security.</p>
                        </div>
                        <div className="techcard">
                            <img src="\assets\tech\cardfour.svg" alt="techcardimg" className="techcardimg" />
                            <h6 className="techcardhead">Off-Chain Transfer Records</h6>
                            <p className="techcardpara">Transactions between DOP wallets don't leave on-chain records, enhancing confidentiality.</p>
                        </div>
                    </div>
                    <div className="techvideo">
                        {/* <h4 className="techvideohead">consequatur aliquid autem vitae</h4> */}
                        {/* <div className="video-player">
                            <video className='video-player-video' ref={videoRef}
                                width="100%"
                                poster="\assets\community\video-thumb.png"
                                onEnded={handleVideoEnded} >
                                <source src="https://res.cloudinary.com/drt6vurtt/video/upload/v1715354454/dop/Binance_Blockchain_Week_2023_-_the_DOP_Experience_hbm5gx.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className={isPlaying ? "pause-button-main" : "play-button-main"}>
                                <button className="play-button" onClick={handlePlayPause}>
                                    {
                                        isPlaying ?
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                                                    <rect x="23" y="15" width="4" height="30" fill="black" />
                                                    <rect x="34" y="15" width="4" height="30" fill="black" />
                                                    <rect x="35" y="13" width="2" height="6" fill="black" />
                                                    <rect x="35" y="41" width="2" height="6" fill="black" />
                                                    <rect x="24" y="13" width="2" height="6" fill="black" />
                                                    <rect x="24" y="41" width="2" height="6" fill="black" />
                                                </svg>
                                                Pause Video
                                            </>
                                            :
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="61" viewBox="0 0 60 61" fill="none">
                                                    <path d="M42.6001 32.6H38.4001V36.8H34.2001V41H30.0001V45.2H25.8001V49.4H21.6001V53.6H17.4001V7.39999H21.6001V11.6H25.8001V15.8H30.0001V20H34.2001V24.2H38.4001V28.4H42.6001V32.6Z" fill="black" />
                                                </svg>
                                                Play Video
                                            </>
                                    }

                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Techcards