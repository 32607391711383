import React, { useEffect, useRef, useState } from "react";
import "./applydopgrant.scss";
import Navbar from "../landing/header/Navbar";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import Footer from "../landing/footer/Footer";
import ContactDetail from "./ContactDetail";
import ProjectOverview from "./ProjectOverview";
import ProjectSpecifics from "./ProjectSpecifics";
import AditionalInfo from "./AditionalInfo";
import Finalize from "./Finalize";

gsap.registerPlugin(ScrollTrigger);

const ApplyForDOP = () => {
    const headingRef = useRef(null);
    const paragraphRef1 = useRef(null);
    const firstSectionRef = useRef(null);
    const secondSectionRef = useRef(null);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const animateText = (ref, speed) => {
            if (!ref.current) return;
            const chars = ref.current.innerText.split('');
            ref.current.innerHTML = '';

            chars.forEach(char => {
                const charSpan = document.createElement('span');
                charSpan.innerText = char;
                ref.current.appendChild(charSpan);
            });

            gsap.from(ref.current.children, {
                y: 100,
                opacity: 0.2,
                stagger: 0.1,
                duration: speed,
                ease: "power1.out",
            });

            gsap.to(ref.current.children, {
                color: '#fff',
                stagger: 0.1,
                duration: speed,
                ease: "power1.out",
            });
        };

        animateText(headingRef, 0.1);
    }, []);

    useEffect(() => {
        const animateText = (ref, speed, animationType) => {
            if (!ref.current) return;
            switch (animationType) {
                case 'fadeIn':
                    gsap.from(ref.current, {
                        opacity: 0,
                        duration: speed,
                        ease: "power1.out",
                    });
                    break;
                case 'slideUp':
                    gsap.from(ref.current, {
                        y: 100,
                        opacity: 0,
                        duration: speed,
                        ease: "power1.out",
                    });
                    break;
                case 'scale':
                    gsap.from(ref.current, {
                        scale: 0,
                        opacity: 0,
                        duration: speed,
                        ease: "power1.out",
                    });
                    break;
                default:
                    break;
            }
        };

        animateText(paragraphRef1, 2, 'scale');
    }, []);

    useEffect(() => {
        const isLargeScreen = window.matchMedia("(min-width: 1500px)").matches;
        const isMobileScreen = window.matchMedia("(max-width: 600px)").matches;

        gsap.to(firstSectionRef.current, {
            scrollTrigger: {
                trigger: secondSectionRef.current,
                start: isMobileScreen ? 'top 40%' : (isLargeScreen ? 'top 35%' : 'top 40%'),
                end: isMobileScreen ? 'bottom top' : (isLargeScreen ? 'bottom top' : 'bottom top'),
                scrub: true,
            },
            filter: 'blur(40px)',
        });
    }, []);

    const [checkforms, setCheckForms] = useState(() => {
        const saved = localStorage.getItem('checkforms');
        return saved !== null ? JSON.parse(saved) : 0;
    });

    const [currentStep, setCurrentStep] = useState(() => {
        const savedCurrentStep = localStorage.getItem('currentStep');
        return savedCurrentStep !== null ? JSON.parse(savedCurrentStep) : 0;
    });

    useEffect(() => {
        localStorage.setItem('checkforms', JSON.stringify(checkforms));
    }, [checkforms]);

    useEffect(() => {
        localStorage.setItem('currentStep', JSON.stringify(currentStep));
    }, [currentStep]);

    const positions = [0, 180, 350, 500, 900]; 

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                left: positions[currentStep],
                behavior: 'smooth',
            });
        }
    }, [currentStep]);

    const handleStepClick = (step) => {
        const savedCheckforms = JSON.parse(localStorage.getItem('checkforms')) || 0;
        if (step <= savedCheckforms) {
            setCurrentStep(step);
        }
    };

    return (
        <>
            <Navbar />
            <section className="maincompany applyfordop" ref={firstSectionRef}>
                <div className="custom-container">
                    <div className="techbannerinner">
                        <h1 className="techhead" ref={paragraphRef1}> <span ref={headingRef}><span className="dnoneonmobile">DOP Grant </span> Application form</span> </h1>
                    </div>
                </div>
            </section>
            <section className="theteam" ref={secondSectionRef}>
                <div className="top-left-text">
                    <p>01. APPLICATIONS</p>
                </div>
                <div className="custom-container">
                    <div className="parent-applicationform">
                        <div className="left-side">
                            <div className="selected-content" ref={scrollContainerRef}>
                                <div className="single-content" onClick={() => handleStepClick(0)}>
                                    <div className={currentStep == 0 ? "num-card active" : "num-card"}>
                                        <p>1</p>
                                    </div>
                                    <div className="text-side">
                                        <h6 className={currentStep == 0 ? "active" : ""}>Contact <br /> DETAILS</h6>
                                    </div>
                                </div>
                                <div className="single-content" onClick={() => handleStepClick(1)}>
                                    <div className={currentStep == 1 ? "num-card active" : "num-card"}>
                                        <p>2</p>
                                    </div>
                                    <div className="text-side">
                                        <h6 className={currentStep == 1 ? "active" : ""}>Project <br /> OVERVIEW</h6>
                                    </div>
                                </div>
                                <div className="single-content" onClick={() => handleStepClick(2)}>
                                    <div className={currentStep == 2 ? "num-card active" : "num-card"}>
                                        <p>3</p>
                                    </div>
                                    <div className="text-side">
                                        <h6 className={currentStep == 2 ? "active" : ""}>Project <br /> Specifics</h6>
                                    </div>
                                </div>
                                <div className="single-content" onClick={() => handleStepClick(3)}>
                                    <div className={currentStep == 3 ? "num-card active" : "num-card"}>
                                        <p>4</p>
                                    </div>
                                    <div className="text-side">
                                        <h6 className={currentStep == 3 ? "active" : ""}>Additional <br /> Information</h6>
                                    </div>
                                </div>
                                <div className="single-content" onClick={() => handleStepClick(4)}>
                                    <div className={currentStep == 4 ? "num-card active" : "num-card"}>
                                        <p>5</p>
                                    </div>
                                    <div className="text-side">
                                        <h6 className={currentStep == 4 ? "active" : ""}>fINALIZE <br /> APPLICATION</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="main-applicationfrom">
                                {
                                    currentStep == 0 ? <ContactDetail setCheckForms={(step) => { setCheckForms(step); setCurrentStep(step); }} />
                                        : currentStep == 1 ? <ProjectOverview setCheckForms={(step) => { setCheckForms(step); setCurrentStep(step); }} />
                                            : currentStep == 2 ? <ProjectSpecifics setCheckForms={(step) => { setCheckForms(step); setCurrentStep(step); }} />
                                                : currentStep == 3 ? <AditionalInfo setCheckForms={(step) => { setCheckForms(step); setCurrentStep(step); }} />
                                                    : <Finalize setCheckForms={(step) => { setCheckForms(step); setCurrentStep(step); }} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ApplyForDOP;
