import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./getintouch.scss";
import { gsap } from "gsap";
import { Modal } from "react-bootstrap";
const Getintouch = ({ secondSectionRef }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const telegramBtnRef = useRef(null);
  const discordBtnRef = useRef(null);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });
  const [formError, setFormError] = useState("");

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.fullName || !formData.email || !formData.message) {
      setFormError("Please fill out all fields");
      return;
    }
    if (!validateEmail(formData.email)) {
      setFormError("Please enter a valid email address");
      return;
    }

    try {
      const response = await axios.post(
        "https://apiclaims.dop.org/contact/us",
        formData
      );
      console.log(response.data);
      setFormError("");

      // Reset form fields after successful submission
      setFormData({
        fullName: "",
        email: "",
        message: "",
      });
      handleShow(true);
      //   toast.success("Form submitted successfully!", {
      //     position: "top-right",
      //     autoClose: 3000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   }
      // );
    } catch (error) {
      console.error("Error:", error.response.data);
      setFormError("Failed to submit the form. Please try again later.");
    }
  };

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split("");

      ref.current.innerHTML = "";
      chars.forEach((char) => {
        const charSpan = document.createElement("span");
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });

      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.03,
        duration: speed,
      });

      gsap.to(ref.current.children, {
        color: "#fff",
        stagger: 0.03,
        duration: speed,
      });
    };

    const handleMouseEnterTelegram = () => {
      animateText(telegramBtnRef, 0.2);
    };

    const handleMouseLeaveTelegram = () => {
      telegramBtnRef.current.innerHTML = "Telegram";
    };

    const handleMouseEnterDiscord = () => {
      animateText(discordBtnRef, 0.2);
    };

    const handleMouseLeaveDiscord = () => {
      discordBtnRef.current.innerHTML = "Discord";
    };

    telegramBtnRef.current.addEventListener(
      "mouseenter",
      handleMouseEnterTelegram
    );
    telegramBtnRef.current.addEventListener(
      "mouseleave",
      handleMouseLeaveTelegram
    );
    discordBtnRef.current.addEventListener(
      "mouseenter",
      handleMouseEnterDiscord
    );
    discordBtnRef.current.addEventListener(
      "mouseleave",
      handleMouseLeaveDiscord
    );

    return () => {
      if (telegramBtnRef.current) {
        telegramBtnRef.current.removeEventListener(
          "mouseenter",
          handleMouseEnterTelegram
        );
        telegramBtnRef.current.removeEventListener(
          "mouseleave",
          handleMouseLeaveTelegram
        );
      }
      if (discordBtnRef.current) {
        discordBtnRef.current.removeEventListener(
          "mouseenter",
          handleMouseEnterDiscord
        );
        discordBtnRef.current.removeEventListener(
          "mouseleave",
          handleMouseLeaveDiscord
        );
      }
    };
  }, []);
  return (
    <>
      <section className="mainget" ref={secondSectionRef}>
        <div className="custom-container">
          <div className="cardparents">
            <div className="parentcardsss">
              <h2>GET IN TOUCH</h2>
              <p>Feel free to drop us a message</p>
              {/* <div className="twiceinputs">
                <input
                  type="text"
                  placeholder="FIRST NAME"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  onFocus={() => setFormError("")}
                />

                <input
                  type="text"
                  placeholder="LAST NAME"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  onFocus={() => setFormError("")}
                />
              </div> */}
              <input
                type="text"
                placeholder="FULL NAME"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                onFocus={() => setFormError("")}
              />
              <input
                type="email"
                placeholder="EMAIL"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                onFocus={() => setFormError("")}
              />
              <textarea
                type="text"
                placeholder="MESSAGE"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                onFocus={() => setFormError("")}
              />
              {formError && (
                <p
                  className="error"
                  style={{
                    backgroundColor: "transparent",
                    color: "red",
                    padding: 8,
                  }}
                >
                  {formError}
                </p>
              )}
              <button className="btnwhite" onClick={handleSubmit}>
                <span className="btntext">Send</span>
                <span className="image">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                  >
                    <path
                      d="M9.20834 6.16668L12.0417 9.00001L9.20834 11.8333M4.95834 6.16668L7.79167 9.00001L4.95834 11.8333"
                      stroke="#0B0B0B"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
              </button>
            </div>
            <div className="parentcardssss">
              <h2>Our online communities are active 24/7</h2>
              <p>
                With our attentive, well equipped community moderators ready to
                provide you with all the needed information.
              </p>
              <div className="twicebtns">
                <a
                  href="https://t.me/dop_community"
                  target="_blank"
                  className="btnwhite"
                >
                  <h6 className="btntext" ref={telegramBtnRef}>
                    Telegram
                  </h6>
                  <span className="image">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M18.4485 4.33596L15.8079 16.7891C15.6086 17.668 15.0891 17.8867 14.3508 17.4727L10.3274 14.5078L8.38599 16.375C8.17115 16.5899 7.99146 16.7696 7.57739 16.7696L7.86646 12.6719L15.3235 5.93362C15.6477 5.64455 15.2532 5.4844 14.8196 5.77346L5.60083 11.5781L1.63208 10.336C0.768802 10.0664 0.753177 9.47268 1.81177 9.05862L17.3352 3.07815C18.054 2.80862 18.6829 3.2383 18.4485 4.33596Z"
                        fill="white"
                      />
                    </svg>
                  </span>{" "}
                </a>
                <a
                  href="https://discord.com/channels/1202241955472605204/1202255234576175174"
                  target="_blank"
                  className="btnwhite"
                >
                  <span className="btntext" ref={discordBtnRef}>
                    Discord
                  </span>{" "}
                  <span className="image">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M17.044 4.9916C14.7522 3.2766 12.5564 3.3248 12.5564 3.3248L12.332 3.5812C15.0566 4.3986 16.3228 5.6006 16.3228 5.6006C12.4246 3.4544 7.45939 3.4698 3.40479 5.6006C3.40479 5.6006 4.71899 4.3344 7.60399 3.517L7.44379 3.3246C7.44379 3.3246 5.26399 3.2766 2.95619 4.9914C2.95619 4.9914 0.648193 9.1424 0.648193 14.2552C0.648193 14.2552 1.99459 16.5632 5.53659 16.6754C5.53659 16.6754 6.12959 15.9702 6.61039 15.3612C4.57499 14.7522 3.80559 13.486 3.80559 13.486C5.08439 14.2862 6.37739 14.787 7.98879 15.1048C10.6104 15.6444 13.8714 15.0896 16.307 13.486C16.307 13.486 15.5056 14.7842 13.406 15.3772C13.8868 15.9702 14.4638 16.6594 14.4638 16.6594C18.0058 16.5472 19.352 14.2392 19.352 14.2554C19.3518 9.1426 17.044 4.9916 17.044 4.9916ZM7.01099 12.7648C6.11339 12.7648 5.37619 11.9794 5.37619 11.0018C5.44179 8.6604 8.59659 8.6676 8.64579 11.0018C8.64579 11.9794 7.92439 12.7648 7.01099 12.7648ZM12.8608 12.7648C11.9632 12.7648 11.226 11.9794 11.226 11.0018C11.298 8.665 14.4134 8.664 14.4956 11.0018C14.4956 11.9794 13.7744 12.7648 12.8608 12.7648Z"
                        fill="white"
                      />
                    </svg>
                  </span>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="contact-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>FORM SUBMITTED</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src="\assets\formsubmitted-icon.svg"
            alt="img"
            className="img-fluid"
          />
          <h6>Thank you for reaching out!</h6>
          <p>Someone from our team will get in touch with you shortly</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Getintouch;
