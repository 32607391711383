import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Environment from '../../utils/Environment';

const ProjectSpecifics = ({ setCheckForms }) => {
    const [features, setFeatures] = useState('');
    const [useCase, setUseCase] = useState('');
    const [benefits, setBenefits] = useState('');
    const [launchTime, setLaunchTime] = useState('');
    const [milestones, setMilestones] = useState([{ name: '', description: '' }]);
    const [projectedUsers, setProjectedUsers] = useState('');
    const [transactionVolume, setTransactionVolume] = useState('');
    const [budget, setBudget] = useState('');
    const [fundingDetails, setFundingDetails] = useState('');
    const [growthStrategy, setGrowthStrategy] = useState('');
    const [githubUrl, setGithubUrl] = useState('');

    const [errors, setErrors] = useState({});

    const api_url = Environment.api_url2;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('applyForm'));
        if (storedData && storedData.projectSpecifics) {
            const { projectSpecifics } = storedData;
            setFeatures(projectSpecifics.capabilities || '');
            setUseCase(projectSpecifics.interact || '');
            setBenefits(projectSpecifics.integration || '');
            setLaunchTime(projectSpecifics.roadMap || '');
            setMilestones(projectSpecifics.milestonesSchema || [{ name: '', description: '' }]);
            setProjectedUsers(projectSpecifics.projectedNumber || '');
            setTransactionVolume(projectSpecifics.estimatedTransaction || '');
            setBudget(projectSpecifics.funds || '');
            setFundingDetails(projectSpecifics.fundsReceived || '');
            setGrowthStrategy(projectSpecifics.strategy || '');
            setGithubUrl(projectSpecifics.gitHubAccess || '');
        }
    }, []);

    const handleAddMilestone = () => {
        setMilestones([...milestones, { name: '', description: '' }]);
    };

    const handleRemoveMilestone = (index) => {
        setMilestones(milestones.filter((_, i) => i !== index));
    };

    const handleMilestoneChange = (index, field, value) => {
        const newMilestones = milestones.map((milestone, i) => {
            if (i === index) {
                return { ...milestone, [field]: value };
            }
            return milestone;
        });
        setMilestones(newMilestones);
    };

    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };

    const today = new Date().toISOString().split('T')[0];
    const validateFields = () => {
        let errors = {};

        if (!features) errors.features = 'Please specify the new features or capabilities your project will bring to DOP users';
        else if (!useCase) errors.useCase = 'Please provide an example use case of how a user would interact with your project using DOP';
        else if (!benefits) errors.benefits = 'Please describe how users will benefit from the integration of your project with DOP';
        else if (!launchTime) errors.launchTime = 'Please select the estimated time to launch your project';
        else if (new Date(launchTime) < new Date(today)) errors.launchTime = 'The launch date cannot be in the past';
        else if (milestones.length === 0 || milestones.some(milestone => !milestone.name || !milestone.description)) {
            errors.milestones = 'All milestones must have a name and description';
        }
        else if (!projectedUsers) errors.projectedUsers = 'Please provide the projected number of users after 6 months';
        else if (!transactionVolume) errors.transactionVolume = 'Please provide the estimated transaction volume through DOP integration after 6 months';
        else if (!budget) errors.budget = 'Please provide a basic budget breakdown detailing how the funds will be used';
        else if (!fundingDetails) errors.fundingDetails = 'Please specify if you have received or applied for other funding and provide details';
        else if (!growthStrategy) errors.growthStrategy = 'Please describe your strategy for attracting users to your DOP-integrated project';
        else if (!githubUrl) errors.githubUrl = 'Please provide the GitHub repository URL if development has started';
        else if (githubUrl && !isValidUrl(githubUrl)) errors.githubUrl = 'Please provide a valid GitHub repository URL';

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    //   const validateFields = () => {
    //     let errors = {};

    //     if (!features) errors.features = 'Please specify the new features or capabilities your project will bring to DOP users';
    //     else if (!useCase) errors.useCase = 'Please provide an example use case of how a user would interact with your project using DOP';
    //     else if (!benefits) errors.benefits = 'Please describe how users will benefit from the integration of your project with DOP';
    //     else if (!launchTime) errors.launchTime = 'Please select the estimated time to launch your project';
    //     else if (milestones.length === 0 || milestones.some(milestone => !milestone.name || !milestone.description)) {
    //       errors.milestones = 'All milestones must have a name and description';
    //     }
    //     else if (!projectedUsers) errors.projectedUsers = 'Please provide the projected number of users after 6 months';
    //     else if (!transactionVolume) errors.transactionVolume = 'Please provide the estimated transaction volume through DOP integration after 6 months';
    //     else if (!budget) errors.budget = 'Please provide a basic budget breakdown detailing how the funds will be used';
    //     else if (!fundingDetails) errors.fundingDetails = 'Please specify if you have received or applied for other funding and provide details';
    //     else if (!growthStrategy) errors.growthStrategy = 'Please describe your strategy for attracting users to your DOP-integrated project';
    //     else if (!githubUrl) errors.githubUrl = 'Please provide the GitHub repository URL if development has started';
    //     else if (githubUrl && !isValidUrl(githubUrl)) errors.githubUrl = 'Please provide a valid GitHub repository URL';

    //     setErrors(errors);
    //     return Object.keys(errors).length === 0;
    //   };

    const handleSubmit = async () => {
        if (!validateFields()) return;

        const projectSpecifics = {
            capabilities: features,
            interact: useCase,
            integration: benefits,
            roadMap: launchTime,
            milestonesSchema: milestones,
            projectedNumber: projectedUsers,
            estimatedTransaction: transactionVolume,
            funds: budget,
            fundsReceived: fundingDetails,
            strategy: growthStrategy,
            gitHubAccess: githubUrl,
        };

        const previousData = JSON.parse(localStorage.getItem('applyForm')) || {};
        const data = {
            ...previousData,
            projectSpecifics: projectSpecifics,
        };

        localStorage.setItem('applyForm', JSON.stringify(data));

        // const config = {
        //   method: 'put',
        //   url: api_url + '/application',
        //   data: data,
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        // };

        // try {
        //   await axios(config);
        setCheckForms(3);
        // } catch (error) {
        //   console.error(error);
        // }
    };

    return (
        <section className="project-overview common-box-style">
            <div className="main-heading">
                <h6>Project Specifics</h6>
            </div>
            <div className="technical-details">
                <div className="option-field">
                    <h6>What new features or capabilities will your project bring to DOP users?</h6>
                    <div className="material-textfield">
                        <textarea
                            placeholder="Provide a detailed explanation"
                            type="text"
                            value={features}
                            onChange={(e) => { setFeatures(e.target.value); setErrors("") }}
                        />
                        <label className='label-textarea'>Your answer here</label>
                       
                    </div>
                    {errors.features && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.features}</p>}
                </div>
                <div className="option-field">
                    <h6>Provide an example use case of how a user would interact with your project using DOP:</h6>
                    <div className="material-textfield">
                        <textarea
                            placeholder="Provide a detailed explanation"
                            type="text"
                            value={useCase}
                            onChange={(e) => { setUseCase(e.target.value); setErrors("") }}
                        />
                        <label className='label-textarea'>Your answer here</label>
                      
                    </div>
                    {errors.useCase && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.useCase}</p>}
                </div>
                <div className="option-field">
                    <h6>How will users benefit from the integration of your project with DOP?</h6>
                    <div className="material-textfield">
                        <textarea
                            placeholder="Provide a detailed explanation"
                            type="text"
                            value={benefits}
                            onChange={(e) => { setBenefits(e.target.value); setErrors("") }}
                        />
                        <label className='label-textarea'>Your answer here</label>
                       
                    </div>
                    {errors.benefits && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.benefits}</p>}
                </div>
            </div>
            <div className="team-information">
                <h6 className='head-text'>Development Roadmap</h6>
                <div className="option-field">
                    <div className="material-textfield">
                        <input
                            placeholder="Select Estimated time to launch"
                            type="date"
                            value={launchTime}
                            min={today}
                            onChange={(e) => { setLaunchTime(e.target.value); setErrors({ ...errors, launchTime: '' }) }}
                        />
                        <label>Estimated time to launch</label>
                        <img src="\assets\dopgrant\calendar-icon.svg" alt="img" className='img-fluid calendar-icon' />

                    </div>
                    {errors.launchTime && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.launchTime}</p>}
                </div>
            </div>
            <div className="key-teammembers">
                <h6 className="head-text">Key milestones in your development process:</h6>
                {milestones.map((milestone, index) => (
                    <div className="single-keymember" key={index}>
                        <h6>Key Milestone {index + 1}</h6>
                        <div className="option-field">
                            <div className="material-textfield">
                                <input
                                    placeholder="Enter Name"
                                    type="text"
                                    value={milestone.name}
                                    onChange={(e) => { handleMilestoneChange(index, 'name', e.target.value); setErrors("") }}
                                />
                                <label>Name</label>
                            </div>
                        </div>
                        <div className="option-field">
                            <div className="material-textfield">
                                <textarea
                                    placeholder="Enter Description"
                                    type="text"
                                    value={milestone.description}
                                    onChange={(e) => { handleMilestoneChange(index, 'description', e.target.value); setErrors("") }}
                                />
                                <label className='label-textarea'>Description</label>

                            </div>
                            {errors.milestones && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.milestones}</p>}
                        </div>
                        {milestones.length > 1 && index !== 0 && (
                            <button className='btn-remove' onClick={() => handleRemoveMilestone(index)}>Remove</button>
                        )}
                    </div>
                ))}
                <button className='btn-another' onClick={handleAddMilestone}>Add KEY MILESTONE</button>
            </div>
            <div className="technical-details">
                <h6 className="head-text">Project Metrics</h6>
                <div className="option-field">
                    <h6>Projected number of users after 6 months:</h6>
                    <div className="material-textfield">
                        <textarea
                            placeholder="Provide a detailed explanation"
                            type="text"
                            value={projectedUsers}
                            onChange={(e) => { setProjectedUsers(e.target.value); setErrors("") }}
                        />
                        <label className='label-textarea'>Your answer here</label>
                    
                    </div>
                    {errors.projectedUsers && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.projectedUsers}</p>}
                </div>
                <div className="option-field">
                    <h6>Estimated transaction volume through DOP integration after 6 months:</h6>
                    <div className="material-textfield">
                        <textarea
                            placeholder="Provide a detailed explanation"
                            type="text"
                            value={transactionVolume}
                            onChange={(e) => { setTransactionVolume(e.target.value); setErrors("") }}
                        />
                        <label className='label-textarea'>Your answer here</label>
                       
                    </div>
                    {errors.transactionVolume && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.transactionVolume}</p>}
                </div>
            </div>
            <div className="technical-details">
                <h6 className="head-text">Funding</h6>
                <div className="option-field">
                    <h6>How will the funds be used? (Please provide a basic budget breakdown)</h6>
                    <div className="material-textfield">
                        <textarea
                            placeholder="Provide a detailed explanation"
                            type="text"
                            value={budget}
                            onChange={(e) => { setBudget(e.target.value); setErrors("") }}
                        />
                        <label className='label-textarea'>Your answer here</label>
                       
                    </div>
                    {errors.budget && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.budget}</p>}
                </div>
                <div className="option-field">
                    <h6>Have you received or applied for other funding? If yes, please provide details.</h6>
                    <div className="material-textfield">
                        <textarea
                            placeholder="Provide a detailed explanation"
                            type="text"
                            value={fundingDetails}
                            onChange={(e) => { setFundingDetails(e.target.value); setErrors("") }}
                        />
                        <label className='label-textarea'>Your answer here</label>
                     
                    </div>
                    {errors.fundingDetails && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.fundingDetails}</p>}
                </div>
            </div>
            <div className="technical-details">
                <h6 className="head-text">Marketing and Growth</h6>
                <div className="option-field">
                    <h6>Describe your strategy for attracting users to your DOP-integrated project:</h6>
                    <div className="material-textfield">
                        <textarea
                            placeholder="Provide a detailed explanation"
                            type="text"
                            value={growthStrategy}
                            onChange={(e) => { setGrowthStrategy(e.target.value); setErrors("") }}
                        />
                        <label className='label-textarea'>Your answer here</label>
                
                    </div>
                    {errors.growthStrategy && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.growthStrategy}</p>}
                </div>
            </div>
            <div className="technical-details">
                <h6 className="head-text">GitHub Access</h6>
                <div className="option-field showblockinmobile">
                    <h6>If development has started, please provide the GitHub repository URL:</h6>
                    <div className="material-textfield">
                        <input
                            placeholder="Enter GitHub repository URL"
                            type="text"
                            value={githubUrl}
                            onChange={(e) => { setGithubUrl(e.target.value); setErrors("") }}
                        />
                        <label className='dnoneinmobile'>GitHub repository URL</label>
                      
                    </div>
                    {errors.githubUrl && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.githubUrl}</p>}
                    <p className="showing-text">In case your repo is private, please grant access to this user: <span>grants@dop.org</span></p>
                   
                    
                </div>
            </div>

            <div className="bottom-btns">
                <button onClick={() => setCheckForms(1)} className="btn-backk">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M8.56543 5.96358L5.7321 8.79692L8.56543 11.6302M12.8154 5.96358L9.9821 8.79692L12.8154 11.6302" stroke="#0B0B0B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <h6>Back</h6>
                </button>
                <button onClick={handleSubmit} className="btn-blackk">
                    <h6>Next</h6>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M9.71387 6.03524L12.5472 8.86857L9.71387 11.7019M5.46387 6.03524L8.2972 8.86857L5.46387 11.7019" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
        </section>
    );
};

export default ProjectSpecifics;
