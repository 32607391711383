// import '../../App.scss';
import React, { useEffect, useRef } from "react";
import Navbar from './landing/header/Navbar';
import Visionbanner from './Vision/Visionbanner';
import Visioncard from "./Vision/Visioncard";
import Visionjoin from "./Vision/Visionjoin";
import Footer from "./landing/footer/Footer";
import { gsap } from 'gsap';
function Visionmain() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);

  useEffect(() => {
    const isLargeScreen = window.matchMedia("(min-width: 1500px)").matches;
    const isMobileScreen = window.matchMedia("(max-width: 600px)").matches;

    gsap.to(firstSectionRef.current, {
        scrollTrigger: {
            trigger: secondSectionRef.current,
            start: isMobileScreen ? 'top 40%' : (isLargeScreen ? 'top 35%' : 'top 40%'),
            end: isMobileScreen ? 'bottom top' : (isLargeScreen ? 'bottom top' : 'bottom top'),
            scrub: true,
        },
        filter: 'blur(40px)',
    });
}, []);

  
  return (
    <>
      <Navbar />
      <Visionbanner firstSectionRef={firstSectionRef}  />
      <Visioncard secondSectionRef={secondSectionRef} />
      <Visionjoin />
      <Footer />
    </>
  );
}
export default Visionmain;