import React, { useEffect, useRef } from 'react';
import "./trans.scss"
import gsap from "gsap";

import { ScrollTrigger } from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);

const Transparency = ({secondSectionRef}) => {
    useEffect(() => {
        const updateScale = () => {
            const scrollY = window.scrollY;
            const scale = scrollY === 0 ? 0.8 : 1;
            gsap.to('.transparency-section', { scale: scale, duration: 1, ease: 'power3.out' });
        };
        updateScale();
        window.addEventListener('scroll', updateScale);
        return () => {
            window.removeEventListener('scroll', updateScale);
        };
    }, []);




    // const textRef1 = useRef(null);
    // const textRef2 = useRef(null);
    // const textRef3 = useRef(null);

    // useEffect(() => {
    //     animateText(textRef1, '#7E7E7E', 0.1);
    //     animateText(textRef2, '#000', 0.1);
    //     animateText(textRef3, '#494949', 0.03);
    // }, []);

    // const animateText = (ref, color, stagger) => {
    //     const chars = ref.current.innerText.split('');
    //     ref.current.innerHTML = '';

    //     chars.forEach((char, i) => {
    //         const charSpan = document.createElement('span');
    //         charSpan.innerText = char;
    //         ref.current.appendChild(charSpan);
    //     });

    //     gsap.from(ref.current.children, {
    //         y: 100,
    //         opacity: 0.2,
    //         stagger: stagger || 0.1,
    //         scrollTrigger: {
    //             trigger: ref.current,
    //             start: 'top 80%',
    //             end: 'bottom 20%',
    //             scrub: true
    //         }
    //     });

    //     gsap.to(ref.current.children, {
    //         color: color || '#7E7E7E',
    //         stagger: stagger || 0.1,
    //         scrollTrigger: {
    //             trigger: ref.current,
    //             start: 'top 80%',
    //             end: 'bottom 20%',
    //             scrub: true
    //         }
    //     });
    // };


    const textRef1 = useRef(null);
    const textRef2 = useRef(null);
    const textRef3 = useRef(null);
    const textRef4 = useRef(null);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        const isMobile = mediaQuery.matches;

        animateText(textRef1, '#7E7E7E', 0.1, isMobile);
        animateText(textRef2, '#000', 0.1, isMobile);
        animateText(textRef3, '#494949', 0.03, isMobile);
        animateText(textRef4, '#000', 0.1, isMobile);

        const handleMediaChange = (e) => {
            const isMobile = e.matches;
            animateText(textRef1, '#7E7E7E', 0.1, isMobile);
            animateText(textRef2, '#000', 0.1, isMobile);
            animateText(textRef3, '#494949', 0.03, isMobile);
            animateText(textRef4, '#000', 0.1, isMobile);
        };

        mediaQuery.addListener(handleMediaChange);

        return () => {
            mediaQuery.removeListener(handleMediaChange);
        };
    }, []);

    const animateText = (ref, color, stagger, isMobile) => {
        const chars = ref.current.innerText.split('');
        ref.current.innerHTML = '';

        chars.forEach((char, i) => {
            const charSpan = document.createElement('span');
            charSpan.innerText = char;
            ref.current.appendChild(charSpan);
        });

        gsap.from(ref.current.children, {
            y: 100,
            opacity: 0.2,
            stagger: stagger || 0.1,
            scrollTrigger: {
                trigger: ref.current,
                start: isMobile ? 'top 150%' : 'top 120%',
                end: isMobile ? 'bottom 20%' : 'bottom 50%',
                scrub: true
            }
        });

        gsap.to(ref.current.children, {
            color: color || '#7E7E7E',
            stagger: stagger || 0.1,
            scrollTrigger: {
                trigger: ref.current,
                start: isMobile ? 'top 150%' : 'top 80%',
                end: isMobile ? 'bottom 20%' : 'bottom 20%',
                scrub: true
            }
        });
    };



    const cardRefs = useRef([]);

    useEffect(() => {
        const revealCards = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    gsap.to(entry.target, { opacity: 1, scale: 1, duration: 0.5 });
                } else {
                    gsap.to(entry.target, { opacity: 0, scale: 0, duration: 0.5 });
                }
            });
        };

        const observer = new IntersectionObserver(revealCards, { threshold: 0 });

        cardRefs.current.forEach(card => {
            observer.observe(card);
        });

        return () => {
            observer.disconnect();
        };
    }, [cardRefs]);




    return (
        <>
            <section className="transparency-section" ref={secondSectionRef}>
                <div className="top-left-text">
                    <p ref={textRef1}>01. DOP Advantages</p>
                </div>
                <div className="custom-container">
                    <div className="main-heading">
                        <h5 ref={textRef2}>Introducing: Selective Transparency</h5>
                        <p ref={textRef3}> DOP’s ingenious solution preserves the essence of decentralized data sharing, while also safeguarding sensitive financial data from prying eyes.</p>
                        <h6>Never before, has a data-protection protocol managed to achieve:</h6>
                    </div>
                    {/* <div className="bottom-cards">
                        <div className="single-card custom-card" ref={ref => cardRefs.current.push(ref)} style={{ opacity: 0 }}>
                            <img src="\assets\landing\flex.svg" alt="img" className='img-fluid' />
                            <h6>FLEXIBLE TRANSPARENCY</h6>
                            <p>DOP empowers users with flexible transparency. You can choose what to share and with whom, whether it's the visibility of specific tokens or the amount of assets.</p>
                        </div>
                        <div className="single-card custom-card" ref={ref => cardRefs.current.push(ref)} style={{ opacity: 0 }}>
                            <img src="\assets\landing\regulation.svg" alt="img" className='img-fluid' />
                            <h6>REGULATION FRIENDLY</h6>
                            <p>DOP is committed to regulation-friendly privacy. By collaborating with industry leaders like Chainalysis and zkMe, we're ensuring a high level of compliance.</p>
                        </div>
                        <div className="single-card custom-card" ref={ref => cardRefs.current.push(ref)} style={{ opacity: 0 }}>
                            <img src="\assets\landing\user.svg" alt="img" className='img-fluid' />
                            <h6>USER EXPERIENCE</h6>
                            <p>DOP ensures that the protocol is fast and easy to use. By integrating DOP with familiar applications, we make it easily accessible and user-friendly.</p>
                        </div>
                    </div> */}
                    <div className="bottom-cards">
                        <div className="single-card custom-card" ref={el => (cardRefs.current[0] = el)} style={{ opacity: 0 }}>
                            <img src="\assets\landing\flex.svg" alt="img" className='img-fluid' />
                            <h6 ref={textRef4}>FLEXIBLE TRANSPARENCY</h6>
                            <p>DOP empowers users with flexible transparency. You can choose what to share and with whom, whether it's the visibility of specific tokens or the amount of assets.</p>
                        </div>
                        <div className="single-card custom-card" ref={el => (cardRefs.current[1] = el)} style={{ opacity: 0 }}>
                            <img src="\assets\landing\regulation.svg" alt="img" className='img-fluid' />
                            <h6 ref={textRef4}>REGULATORY COMPLIANCE</h6>
                            <p>DOP is committed to regulation-friendly privacy. By collaborating with industry leaders like Chainalysis and zkMe, we're ensuring a high level of compliance.</p>
                        </div>
                        <div className="single-card custom-card" ref={el => (cardRefs.current[2] = el)} style={{ opacity: 0 }}>
                            <img src="\assets\landing\user.svg" alt="img" className='img-fluid' />
                            <h6 ref={textRef4}>USER EXPERIENCE</h6>
                            <p>DOP ensures that the protocol is fast and easy to use. By integrating DOP with familiar applications, we make it easily accessible and user-friendly.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Transparency
