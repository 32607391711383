import React, { useEffect, useRef } from 'react'
import './communityactivity.scss'
import { gsap } from 'gsap';

const Communityactivity = () => {
    const wrapperRef = useRef(null);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        const cloneAndAppendImages = () => {
            const images = wrapper.querySelectorAll('.communitycard');
            const totalWidth = Array.from(images).reduce((acc, img) => acc + img.offsetWidth, 0);
            const clonedImages = Array.from(images).map(img => img.cloneNode(true));

            clonedImages.forEach(img => wrapper.appendChild(img));
            wrapper.style.width = `${totalWidth * 2}px`;
        };

        const animateScroll = () => {
            gsap.to(wrapper, {
                x: -wrapper.offsetWidth / 2,
                duration: wrapper.offsetWidth / 100,
                ease: 'linear',
                onComplete: () => {
                    gsap.set(wrapper, { x: 0 });
                    animateScroll();
                }
            });
        };

        cloneAndAppendImages();
        animateScroll();

        const pauseAnimation = () => gsap.pause();
        const resumeAnimation = () => gsap.resume();

        wrapper.addEventListener('mouseenter', pauseAnimation);
        wrapper.addEventListener('mouseleave', resumeAnimation);

        return () => {
            wrapper.removeEventListener('mouseenter', pauseAnimation);
            wrapper.removeEventListener('mouseleave', resumeAnimation);
        };
    }, []);


    // const wrapperRefSecond = useRef(null);

    // useEffect(() => {
    //     const wrapper = wrapperRefSecond.current;

    //     const cloneAndAppendImages = () => {
    //         const images = wrapper.querySelectorAll('.communitycard');
    //         const totalWidth = Array.from(images).reduce((acc, img) => acc + img.offsetWidth, 0);
    //         const clonedImages = Array.from(images).map(img => img.cloneNode(true));

    //         clonedImages.forEach(img => wrapper.appendChild(img));
    //         wrapper.style.width = `${totalWidth * 2}px`;
    //     };

    //     const animateScroll = () => {
    //         gsap.to(wrapper, {
    //             x: -wrapper.offsetWidth / 2,
    //             duration: wrapper.offsetWidth / 100,
    //             ease: 'linear',
    //             onComplete: () => {
    //                 gsap.set(wrapper, { x: 0 });
    //                 animateScroll();
    //             }
    //         });
    //     };

    //     cloneAndAppendImages();
    //     animateScroll();

    //     return () => {
            
    //     };
    // }, []);


    return (
        <>
            <section className="communityactivity">
                <div className="communityactivitycardsheader">
                    <p className="communityactivitycardsheaderpara">
                        <span>04.</span>
                        <span>Community Activity</span>
                    </p>
                </div>
                {/* <div className="image-container">
                    <div className="wrapper" ref={wrapperRef}>
                        <img src="\assets\community\card1.png" alt="communitycardimg" className="communitycardimg imgone" />
                        <img src="\assets\community\card2.png" alt="communitycardimg" className="communitycardimg imgtwo" />
                        <img src="\assets\community\card3.png" alt="communitycardimg" className="communitycardimg imgthree" />
                        <img src="\assets\community\card4.png" alt="communitycardimg" className="communitycardimg imgfour" />
                        <img src="\assets\community\card5.png" alt="communitycardimg" className="communitycardimg imgfive" />
                    </div>
                </div> */}

                <div className="communityactivityinner">
                    <h4 className="communityactivityhead">Community Activities</h4>
                    <div style={{ overflow: "hidden" }}>
                        <div className="communitymaincards" ref={wrapperRef}>
                            <div className="communitycard">
                                <div className="communitycardimg img-border-style">
                                    <img src="\assets\community\card1.png" alt="communitycardimg" className="communitycardimg imgone" />
                                </div>
                            </div>
                            <div className="communitycard">
                                <div className="communitycardimg img-border-style">
                                    <img src="\assets\community\card2.png" alt="communitycardimg" className="communitycardimg imgtwo" />
                                </div>
                            </div>
                            <div className="communitycard">
                                <div className="communitycardimg img-border-style">
                                    <img src="\assets\community\card3.png" alt="communitycardimg" className="communitycardimg imgthree" />
                                </div>
                            </div>
                            <div className="communitycard">
                                <div className="communitycardimg img-border-style">
                                    <img src="\assets\community\card4.png" alt="communitycardimg" className="communitycardimg imgfour" />
                                </div>
                            </div>
                            <div className="communitycard">
                                <div className="communitycardimg img-border-style">
                                    <img src="\assets\community\card5.png" alt="communitycardimg" className="communitycardimg imgfive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <h4 className="communityactivityhead">Community Activity Archives</h4>
                    <div style={{ overflow: "hidden" }}>
                        <div className="communitymaincards" ref={wrapperRefSecond}>
                            <div className="communitycard">
                                <div className="communitycardimg">
                                    <img src="\assets\community\card5.png" alt="communitycardimg" className="communitycardimg imgone" />
                                </div>
                                <h6 className="communitycardhead">Activity Title</h6>
                            </div>
                            <div className="communitycard">
                                <div className="communitycardimg">
                                    <img src="\assets\community\card3.png" alt="communitycardimg" className="communitycardimg imgtwo" />
                                </div>
                                <h6 className="communitycardhead">Activity Title</h6>
                            </div>
                            <div className="communitycard">
                                <div className="communitycardimg">
                                    <img src="\assets\community\card2.png" alt="communitycardimg" className="communitycardimg imgthree" />
                                </div>
                                <h6 className="communitycardhead">Activity Title</h6>
                            </div>
                            <div className="communitycard">
                                <div className="communitycardimg">
                                    <img src="\assets\community\card1.png" alt="communitycardimg" className="communitycardimg imgfour" />
                                </div>
                                <h6 className="communitycardhead">Activity Title</h6>
                            </div>
                            <div className="communitycard">
                                <div className="communitycardimg">
                                    <img src="\assets\community\card4.png" alt="communitycardimg" className="communitycardimg imgfive" />
                                </div>
                                <h6 className="communitycardhead">Activity Title</h6>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default Communityactivity