import React, { useEffect, useRef } from "react";
import "./explainer.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { gsap } from 'gsap';
const Explainer = () => {
  // const owl_option = {
  //   nav: true,
  //   dots: false,
  //   dotsEach: false,
  //   loop: true,
  //   autoplay: false,
  //   navText: [
  //     //   "<i class='fas fa-angle-left'></i>",
  //     //   "<i class='fas fa-angle-right'></i>",
  //   ],
  //   responsive: {
  //     0: {
  //       items: 1.2,
  //       margin: 9,
  //       loop: true
  //     },
  //     361: {
  //       items: 1.2,
  //       margin: 9,
  //       loop: true
  //     },
  //     600: {
  //       items: 1.2,
  //       margin: 9,
  //       loop: true
  //     },
  //     700: {
  //       items: 3,
  //       margin: 9,
  //     },
  //     1000: {
  //       items: 3.2,
  //       margin: 9,
  //     },
  //     1200: {
  //       items: 3.2,
  //       margin: 9,
  //     },
  //   },
  // };
  const btnRef = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split('');

      ref.current.innerHTML = '';
      chars.forEach(char => {
        const charSpan = document.createElement('span');
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });

      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.03,
        duration: speed
      });

      gsap.to(ref.current.children, {
        color: '#fff',
        stagger: 0.03,
        duration: speed
      });
    };

    const btn = btnRef.current;

    const handleMouseEnter = () => {
      animateText(btnRef, 0.2);
    };

    const handleMouseLeave = () => {
      btnRef.current.innerHTML = btn.innerText;
    };

    btn.addEventListener('mouseenter', handleMouseEnter);
    btn.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      btn.removeEventListener('mouseenter', handleMouseEnter);
      btn.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);
  return (
    <>
      {/* <section className="mainexplainer">
        <div className="dopphasesheader">
          <p className="dopphasesheaderpara">
            <span>04.</span>
            <span>Explainer videos</span>
          </p>
        </div>
        <div className="custom-container">
          <div className="blogsinner">
            <div className="mainhead">
              <h2>Explainer videos</h2>
            </div>
          </div>
          <div className="mainparentcards">
            <div class="owl_option">
              <OwlCarousel className="owl-theme" {...owl_option}>
                <div class="item">
                  <div className="mainimagessss">
                    <img
                      src="\assets\Company\viedo.png"
                      alt="img"
                      className="img-fluid innerimage"
                    />
                  </div>
                </div>
                <div class="item">
                  <div className="mainimagessss">
                    <img
                      src="\assets\Company\viedo1.png"
                      alt="img"
                      className="img-fluid innerimage"
                    />
                  </div>
                </div>
                <div class="item">
                  <div className="mainimagessss">
                    <img
                      src="\assets\Company\viedo2.png"
                      alt="img"
                      className="img-fluid innerimage"
                    />
                  </div>
                </div>
                <div class="item">
                  <div className="mainimagessss">
                    <img
                      src="\assets\Company\viedo.png"
                      alt="img"
                      className="img-fluid innerimage"
                    />
                  </div>
                </div>
                <div class="item">
                  <div className="mainimagessss">
                    <img
                      src="\assets\Company\viedo1.png"
                      alt="img"
                      className="img-fluid innerimage"
                    />
                  </div>
                </div>
                <div class="item">
                  <div className="mainimagessss">
                    <img
                      src="\assets\Company\viedo2.png"
                      alt="img"
                      className="img-fluid innerimage"
                    />
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section> */}
      <section className="mainnet">
        <div className="mainhead">
          <h2>DOP MAINNET IS NOW LIVE!</h2>
          <p>THIS IS WHAT IT’S LIKE TO ACHIEVE DATA OWNERSHIP ON THE BLOCKCHAIN</p>
          <a href="https://app.dop.org/" className="btn-launch" target="_blank" >
            <h6 ref={btnRef} >LAUNCH APP</h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333"
                stroke="white"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>

        </div>
        <div className="mobile-img-laptop d-none">
          <img src="\assets\mobile-laptop-img.png" alt="img" className="img-fluid" />
        </div>
      </section>
    </>
  );
};

export default Explainer;
