import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Environment from '../../utils/Environment';

const ProjectOverview = ({ setCheckForms }) => {
    const [projectName, setProjectName] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [stage, setStage] = useState('');
    const [teamSize, setTeamSize] = useState('');
    const [keyTeamMembers, setKeyTeamMembers] = useState([{ name: '', role: '', experience: '' }]);
    const [relevantDevelopment, setRelevantDevelopment] = useState('');
    const [integrateWithDop, setIntegrateWithDop] = useState('');
    const [interactionsWithDop, setInteractionsWithDop] = useState('');
    const [enhanceWithDop, setEnhanceWithDop] = useState('');
    const [errors, setErrors] = useState({});

    const api_url = Environment.api_url2;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);

        const storedData = JSON.parse(localStorage.getItem('applyForm'));
        if (storedData && storedData.projectOverview) {
            const { projectOverview } = storedData;
            setProjectName(projectOverview.name || '');
            setDescription(projectOverview.description || '');
            setCategory(projectOverview.category || '');
            setStage(projectOverview.stage || '');
            setTeamSize(projectOverview.teamInfo || '');
            setKeyTeamMembers(projectOverview.keyTeamSchema || [{ name: '', role: '', experience: '' }]);
            setRelevantDevelopment(projectOverview.relevantDevelopment || '');
            setIntegrateWithDop(projectOverview.integrateWithDop || '');
            setInteractionsWithDop(projectOverview.interactionsWithDop || '');
            setEnhanceWithDop(projectOverview.enhanceWithDop || '');
        }
    }, []);

    const handleAddTeamMember = () => {
        setKeyTeamMembers([...keyTeamMembers, { name: '', role: '', experience: '' }]);
    };

    const handleRemoveTeamMember = (index) => {
        setKeyTeamMembers(keyTeamMembers.filter((_, i) => i !== index));
    };

    const handleTeamMemberChange = (index, field, value) => {
        const newTeamMembers = keyTeamMembers.map((member, i) => {
            if (i === index) {
                return { ...member, [field]: value };
            }
            return member;
        });
        setKeyTeamMembers(newTeamMembers);
    };

    const validate = () => {
        const newErrors = {};
        if (!projectName) newErrors.projectName = 'Project Category is required';
        else if (!description) newErrors.description = 'Project Description is required.';
        else if (description.length > 200) newErrors.description = 'Description must not exceed 200 words.';
        else if (!category) newErrors.category = 'Project Category is a required';
        else if (!stage) newErrors.stage = 'Project Stage is a required field';
        else if (!teamSize) newErrors.teamSize = 'Team Size info is a required field';
        else if (keyTeamMembers.some(member => !member.name || !member.role || !member.experience)) {
            newErrors.keyTeamMembers = 'Key team is a required field';
        }
        else if (!relevantDevelopment) newErrors.relevantDevelopment = 'Relevant development experience info is a required field';
        else if (!integrateWithDop) newErrors.integrateWithDop = 'Technical detailed description for integrate with DOP is a required field';
        else if (!interactionsWithDop) newErrors.interactionsWithDop = 'Key interactions with DOP are required.';
        else if (!enhanceWithDop) newErrors.enhanceWithDop = 'Project enhance transparency and confidentiality is a required field';
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {   
        if (!validate()) {
            return;
        }
        const projectOverview = {
            name: projectName,
            description,
            category,
            stage,
            teamInfo: teamSize,
            keyTeamSchema: keyTeamMembers,
            relevantDevelopment,
            integrateWithDop,
            interactionsWithDop,
            enhanceWithDop,
        };

        const previousData = JSON.parse(localStorage.getItem('applyForm')) || {};

        const updatedData = {
            ...previousData,
            projectOverview,
        };

        localStorage.setItem('applyForm', JSON.stringify(updatedData));

        // const config = {
        //     method: 'put',
        //     url: api_url + '/application',
        //     data: updatedData,
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        // };

        // try {
        //     await axios(config);
            setCheckForms(2);
        // } catch (error) {
        //     console.error(error);
        // }
    };

    return (
        <>
            <section className="project-overview common-box-style">
                <div className="main-heading">
                    <h6>Project Overview</h6>
                </div>
                <div className="bottom-content">
                    <div className="option-field">
                        <div className="material-textfield">
                            <input
                                placeholder="Enter Project Name"
                                type="text"
                                value={projectName}
                                onChange={(e) => { setProjectName(e.target.value); setErrors("") }}
                            />
                            <label>Project Name</label>

                        </div>
                        {errors.projectName && <p className="error-text mt-2" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.projectName}</p>}
                    </div>
                    <div className="option-field">
                        <div className="material-textfield">
                            <textarea
                                placeholder="Max 200 words"
                                value={description}
                                onChange={(e) =>{ setDescription(e.target.value); setErrors("") }}
                            />
                            <label className='label-textarea'>Short Description</label>
              
                        </div>
                        {errors.description && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.description}</p>}
                    </div>
                    <div className="option-field">
                        <div className="dropdown">
                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <label  className={category ? "selected-categroy" : ""}>Project Category</label> {category && <h6 style={{ color: "#000", fontWeight: "500" }}>{category}</h6> || 'Select Project Category'}
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <path
                                        d="M11.2264 5.5683H7.59221H4.31971C3.75971 5.5683 3.47971 6.24497 3.87638 6.64163L6.89805 9.6633C7.38221 10.1475 8.16971 10.1475 8.65388 9.6633L9.80305 8.51413L11.6755 6.64163C12.0664 6.24497 11.7864 5.5683 11.2264 5.5683Z"
                                        fill="black"
                                    />
                                </svg>
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" onClick={() => {setCategory('GameFi');setErrors("")}}>GameFi: Blockchain-based games with play-to-earn elements.</a></li>
                                <li><a className="dropdown-item" onClick={() => {setCategory('Marketplace');setErrors("")}}>Marketplace: Platforms for buying and selling digital assets or NFTs.</a></li>
                                <li><a className="dropdown-item" onClick={() => {setCategory('Lending');setErrors("")}}>Lending: Crypto lending platforms allow users to borrow or lend assets.</a></li>
                                <li><a className="dropdown-item" onClick={() => {setCategory('CeFi');setErrors("")}}>CeFi: Centralized finance, traditional financial services using cryptocurrencies.</a></li>
                                <li><a className="dropdown-item" onClick={() => {setCategory('DeFi');setErrors("")}}>DeFi: Decentralized finance, peer-to-peer financial services on blockchain.</a></li>
                                <li><a className="dropdown-item" onClick={() => {setCategory('Social');setErrors("")}}>Social: Crypto-integrated social media or community platforms.</a></li>
                                <li><a className="dropdown-item" onClick={() => {setCategory('Meme');setErrors("")}}>Meme: Projects inspired by internet culture and viral content.</a></li>
                                <li><a className="dropdown-item" onClick={() => {setCategory('Wallet');setErrors("")}}>Wallet: Software or hardware for storing and managing cryptocurrencies.</a></li>
                                <li><a className="dropdown-item" onClick={() => {setCategory('Compliance');setErrors("")}}>Compliance: Tools or services ensuring adherence to crypto regulations.</a></li>
                            </ul>                    
                        </div>
                        {errors.category && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.category}</p>}
                    </div>
                    <div className="option-field">
                        <div className="dropdown">
                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <label className={stage ? "selected-categroy" : ""}>Project Stage</label> {stage && <h6 style={{ color: "#000", fontWeight: "500" }}>{stage}</h6> || 'Select Project Stage'}{' '}
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <path
                                        d="M11.2264 5.5683H7.59221H4.31971C3.75971 5.5683 3.47971 6.24497 3.87638 6.64163L6.89805 9.6633C7.38221 10.1475 8.16971 10.1475 8.65388 9.6633L9.80305 8.51413L11.6755 6.64163C12.0664 6.24497 11.7864 5.5683 11.2264 5.5683Z"
                                        fill="black"
                                    />
                                </svg>
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" onClick={() =>{ setStage('Idea');setErrors("")}}>Idea</a></li>
                                <li><a className="dropdown-item" onClick={() => {setStage('Prototype');setErrors("")}}>Prototype</a></li>
                                <li><a className="dropdown-item" onClick={() => {setStage('Beta');setErrors("")}}>Beta</a></li>
                                <li><a className="dropdown-item" onClick={() => {setStage('Live');setErrors("")}}>Live</a></li>
                            </ul>
                        
                        </div>
                        {errors.stage && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.stage}</p>}
                    </div>
                </div>
                <div className="team-information">
                    <h6 className='head-text'>Team Information</h6>
                    <div className="option-field">
                        <div className="material-textfield">
                            <input
                                placeholder="Enter Team Size"
                                type="text"
                                value={teamSize}
                                onChange={(e) => {setTeamSize(e.target.value); setErrors("") }}
                            />
                            <label>Team Size</label>
                         
                        </div>
                        {errors.teamSize && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.teamSize}</p>}
                    </div>
                </div>
                <div className="key-teammembers">
                    <h6 className="head-text">Key Team Members</h6>
                    {keyTeamMembers.map((member, index) => (
                        <div className="single-keymember" key={index}>
                            <h6>Key Team Member {index + 1}</h6>
                            <div className="option-field">
                                <div className="material-textfield">
                                    <input
                                        placeholder="Enter Name"
                                        type="text"
                                        value={member.name}
                                        onChange={(e) => {handleTeamMemberChange(index, 'name', e.target.value); setErrors("") }}
                                    />
                                    <label>Name</label>
                                </div>
                            </div>
                            <div className="option-field">
                                <div className="material-textfield">
                                    <input
                                        placeholder="Enter Role"
                                        type="text"
                                        value={member.role}
                                        onChange={(e) =>{ handleTeamMemberChange(index, 'role', e.target.value); setErrors("") }}
                                    />
                                    <label>Role</label>
                                </div>
                            </div>
                            <div className="option-field">
                                <div className="material-textfield">
                                    <textarea
                                        placeholder="Enter Experience"
                                        type="text"
                                        value={member.experience}
                                        onChange={(e) => {handleTeamMemberChange(index, 'experience', e.target.value); setErrors("") }}
                                    />
                                    <label className='label-textarea'>Experience</label>
                                </div>
                            </div>   
                            {errors.keyTeamMembers && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.keyTeamMembers}</p>}   
                            {keyTeamMembers.length > 1 && index !== 0 && (          
                            <button className='btn-remove' onClick={() => handleRemoveTeamMember(index)}>Remove</button>
                            )}
                        </div>
                    ))}
                    <button className='btn-another' onClick={handleAddTeamMember}>Add team member</button>
                    <div className="option-field">
                        <div className="material-textfield">
                            <textarea
                                placeholder="Enter Relevant development experience"
                                type="text"
                                value={relevantDevelopment}
                                onChange={(e) => {setRelevantDevelopment(e.target.value); setErrors("") }}
                            />
                            <label className='label-textarea'>Relevant development experience</label>
                         
                        </div>
                        {errors.relevantDevelopment && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.relevantDevelopment}</p>}
                    </div>
                </div>
                <div className="technical-details">
                    <h6 className="head-text">Technical Details</h6>
                    <div className="option-field">
                        <h6>Describe how your project will integrate with DOP:</h6>
                        <div className="material-textfield">
                            <textarea
                                placeholder="Provide detailed description"
                                type="text"
                                value={integrateWithDop}
                                onChange={(e) => {setIntegrateWithDop(e.target.value); setErrors("") }}
                            />
                            <label className='label-textarea'>Your answer here</label>
                        
                        </div>
                        {errors.integrateWithDop && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.integrateWithDop}</p>}
                    </div>
                    <div className="option-field">
                        <h6>Outline the key interactions with DOP:</h6>
                        <div className="material-textfield">
                            <textarea
                                placeholder="Provide detailed description"
                                type="text"
                                value={interactionsWithDop}
                                onChange={(e) => {setInteractionsWithDop(e.target.value); setErrors("") }}
                            />
                            <label className='label-textarea'>Your answer here</label>
                      
                        </div>
                        {errors.interactionsWithDop && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.interactionsWithDop}</p>}
                    </div>
                    <div className="option-field">
                        <h6>How will your project enhance selective transparency and confidentiality for users through DOP integration?</h6>
                        <div className="material-textfield">
                            <textarea
                                placeholder="Provide detailed description"
                                type="text"
                                value={enhanceWithDop}
                                onChange={(e) => {setEnhanceWithDop(e.target.value); setErrors("") }}
                            />
                            <label className='label-textarea'>Your answer here</label>
                       
                        </div>
                        {errors.enhanceWithDop && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.enhanceWithDop}</p>}
                    </div>
                </div>
                <div className="bottom-btns">
                    <button onClick={() => setCheckForms(0)} className="btn-backk">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M8.56543 5.96358L5.7321 8.79692L8.56543 11.6302M12.8154 5.96358L9.9821 8.79692L12.8154 11.6302" stroke="#0B0B0B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <h6>Back</h6>
                    </button>
                    <button onClick={handleSubmit} className="btn-blackk">
                        <h6>Next</h6>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M9.71387 6.03524L12.5472 8.86857L9.71387 11.7019M5.46387 6.03524L8.2972 8.86857L5.46387 11.7019" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
            </section>
        </>
    );
};

export default ProjectOverview;
