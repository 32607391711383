import React from 'react'
import "./ambassador.scss"

const ComingSoon = ({ secondSectionRef }) => {
    return (
        <>
            <section className="visioncard comingsoon-page" ref={secondSectionRef}>
                <div className="dopphasesheader">
                    <p className="dopphasesheaderpara">
                        <span>01.</span>
                        <span>AmbassaDOPs</span>
                    </p>
                </div>
                <div className="custom-container">
                    <div className="parent">
                        <img src="\assets\comingsoon.png" alt="img" className='img-fluid' />
                        <p>DOP Brand Ambassador Program</p>
                        <h6>Coming soon!</h6>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ComingSoon
