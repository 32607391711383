import React, { useEffect, useRef, useState } from "react";
import "./grant.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Navbar from "../landing/header/Navbar";
import Lottie from "lottie-web";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import Footer from "../landing/footer/Footer";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Environment from "../../utils/Environment";
import { ToastContainer, toast } from "react-toastify";
import AppOpeningSoon from "./AppOpeningSoon";
gsap.registerPlugin(ScrollTrigger);


const Grant = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const api_url = Environment.api_url;
    const [name, setName] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [documentUrl, setDocumentUrl] = useState("");
    const [nameerr, setNameErr] = useState("");
    const [countryerr, setCountryErr] = useState("");
    const [emailerr, setEmailErr] = useState("");
    const [documentUrlerr, setDocumentUrlErr] = useState("");
    const fileInputRef = useRef(null);

    const handleImageChange = async (e) => {
        setDocumentUrlErr("")
        const file = e.target.files[0];
        if (file) {
            if (file.type.includes('image') || file.type.includes('pdf')) {
                const reader = new FileReader();
                reader.onload = async () => {
                    try {
                        const uploadedUrl = await getPdf(file);
                        setDocumentUrl(uploadedUrl);
                    } catch (error) {
                        toast.error("The file format you provided is not supported, please add a PNG, JPG, JPEG, or PDF");
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    };

    // const dataURLtoFile = (dataurl, filename) => {
    //   let arr = dataurl.split(',');
    //   let mime = arr[0].match(/:(.*?);/)[1];
    //   let bstr = atob(arr[1]);
    //   let n = bstr.length;
    //   let u8arr = new Uint8Array(n);
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }
    //   return new File([u8arr], filename, { type: mime });
    // };

    const getPdf = async (file) => {
        const formData = new FormData();
        formData.append("document", file);

        try {
            const response = await axios.post(
                `${api_url}/users/pdf-upload`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response?.data?.url) {
                return response.data.url;
            }
        } catch (error) {
            throw error;
        }
    };
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const getGrantForm = async () => {
        if (name.length === 0) {
            setNameErr("Name is Required");
        } else if (country.length === 0) {
            setCountryErr("Select Country");
        } else if (email.length === 0) {
            setEmailErr("Email is Required");
        } else if (!isValidEmail(email)) {
            setEmailErr("Email is invalid");
        } else if (!documentUrl) {
            setDocumentUrlErr("Doucument is required");
        } else {
            try {
                const config = {
                    method: "post",
                    url: `${api_url}/temp/dop-grant`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: {
                        fullName: name,
                        country: country,
                        email: email,
                        documentUrl: documentUrl,
                    },
                };
                const response = await axios(config);
                const data = response?.data?.data;
                setName("")
                setCountry("")
                setEmail("")
                setDocumentUrl("")
                toast.success("Grant Form Submitted Successfully!")
            } catch (error) {
                console.error("API Request Error:", error);
            }
        }
    };

    const headingRef = useRef(null);

    useEffect(() => {
        const animateText = (ref, speed) => {
            const chars = ref.current.innerText.split('');


            ref.current.innerHTML = '';


            chars.forEach(char => {
                const charSpan = document.createElement('span');
                charSpan.innerText = char;
                ref.current.appendChild(charSpan);
            });


            gsap.from(ref.current.children, {
                y: 100,
                opacity: 0.2,
                stagger: 0.1,
                duration: speed
            });

            gsap.to(ref.current.children, {
                color: '#fff',
                stagger: 0.1,
                duration: speed
            });
        };

        animateText(headingRef, 0.1);
    }, []);


    const paragraphRef1 = useRef(null);

    useEffect(() => {
        const animateText = (ref, speed, animationType) => {
            switch (animationType) {
                case 'fadeIn':
                    gsap.from(ref.current, {
                        opacity: 0,
                        duration: speed
                    });
                    break;
                case 'slideUp':
                    gsap.from(ref.current, {
                        y: 100,
                        opacity: 0,
                        duration: speed
                    });
                    break;
                case 'scale':
                    gsap.from(ref.current, {
                        scale: 0,
                        opacity: 0,
                        duration: speed
                    });
                    break;
                default:
                    break;
            }
        };

        animateText(paragraphRef1, 2, 'scale');
    }, []);


    const firstSectionRef = useRef(null);
    const secondSectionRef = useRef(null);


    useEffect(() => {
        const isLargeScreen = window.matchMedia("(min-width: 1500px)").matches;
        const isMobileScreen = window.matchMedia("(max-width: 600px)").matches;

        gsap.to(firstSectionRef.current, {
            scrollTrigger: {
                trigger: secondSectionRef.current,
                start: isMobileScreen ? 'top 40%' : (isLargeScreen ? 'top 35%' : 'top 40%'),
                end: isMobileScreen ? 'bottom top' : (isLargeScreen ? 'bottom top' : 'bottom top'),
                scrub: true,
            },
            filter: 'blur(40px)',
        });
    }, []);




    const handleCountrySelect = (selectedCountry) => {
        setCountryErr("")
        setCountry(selectedCountry);
    };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Navbar />
            <section className="maincompany" ref={firstSectionRef}>
                <div className="custom-container">
                    <div className="techbannerinner">
                        <h1 className="techhead" ref={paragraphRef1}> <span ref={headingRef}>DOP Grant Program</span> </h1>
                    </div>
                </div>
            </section>
            <section className="theteam dopgrant-section dopgrant-sectionabove" ref={secondSectionRef}>
                <div className="top-left-text">
                    <p>1. DOP Grant Program</p>
                </div>
                <div className="custom-container ">
                    <div className="parenttext">
                        <div className="left">
                            <p>
                                At DOP, we are committed to fostering innovation and supporting the development of products, platforms, and applications that leverage our decentralized data ownership protocol.
                            </p>
                        </div>
                        <div className="right">
                            <p>
                                To this end, we are launching the DOP Grant Program, a dedicated initiative aimed at providing financial support and resources to developers and teams working on projects that align with our mission.
                            </p>
                        </div>
                    </div>
                    <div className="parenttexts">
                        <div className="left">
                            <h6 className="head-text">Program Overview</h6>
                            <p>
                                The DOP Grant Program has a total budget of $5,000,000 worth of DOP tokens, which will be allocated to fund a diverse range of projects across various stages of development. Our goal is to empower creators, entrepreneurs, and innovators who share our vision of enabling individuals to truly own and control their personal data.
                            </p>
                            <br />
                            <p>We welcome proposals from individuals, teams, and organizations worldwide, regardless of their size or experience. Whether you are a seasoned developer, a passionate hobbyist, or a visionary entrepreneur, we encourage you to apply and share your ideas with us.
                            </p>
                            <Link to="/apply" style={{ width: "265px" }} className="btn-launch">
                            <h6 style={{width: "100%", textAlign: "center"}}>APPLY NOW</h6>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                            >
                                <path
                                    d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333"
                                    stroke="white"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </Link>
                        </div>
                        <div className="right">
                            <div className="mainimg">
                                <img
                                    src="\assets\dopgrant\programoverview-new.svg"
                                    alt="img"
                                    className="img-fluid images"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="usecasesmain usecasesmainforgrant">
                <div className="usecasesheader">
                    <p className="usecasesheaderpara">
                        <span>2.</span>
                        <span>Eligibility Criteria</span>
                    </p>
                </div>
                <div className="custom-container">
                    <div className="bottom-part">
                        <div className="main-heading">
                            <h6>Eligibility Criteria</h6>
                            <p>To be eligible for the DOP Grant Program, your project must meet the following criteria:</p>
                        </div>
                        <div className="bottom-cards">
                            <div className="single-card">
                                <h6><span>Alignment with DOP's Mission:</span> Your project should directly or indirectly contribute to the adoption and development of DOP's decentralized data ownership protocol.</h6>
                            </div>
                            <div className="single-card">
                                <h6><span>Technical Feasibility:</span> Your proposal should demonstrate a clear understanding of the technical challenges involved and present a viable solution.</h6>
                            </div>
                            <div className="single-card">
                                <h6><span>Innovation:</span> We value projects that introduce novel ideas, approaches, or solutions to existing problems within the data ownership and privacy ecosystem.</h6>
                            </div>
                            <div className="single-card">
                                <h6><span>Community Impact:</span> Your project should have the potential to positively impact the DOP community and the broader ecosystem of data ownership and privacy.    </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="theteam dopgrant-section">
                <div className="top-left-text">
                    <p>3. Application Process</p>
                </div>
                <div className="custom-container ">
                    <div className="application-program">
                        <div className="left-side">
                            <h4>Application Process</h4>
                            <p>To apply for the DOP Grant Program, please follow these steps:</p>
                        </div>
                        <div className="right-side">
                            <div className="inside-items">
                                <img src="\assets\dopgrant\prepareyourproposal.svg" alt="img" className="img-fluid" />
                                <div className="text">
                                    <h6>Prepare Your Proposal:</h6>
                                    <p>Develop a comprehensive proposal that outlines your project's objectives, technical approach, team composition, timeline, and budget requirements.</p>
                                </div>
                            </div>
                            <div className="inside-items">
                                <img src="\assets\dopgrant\submit.svg" alt="img" className="img-fluid" />
                                <div className="text">
                                    <h6>Submit Your Application:</h6>
                                    <p>Complete the online submission form on this page, attaching your proposal and any supporting materials.                                </p>
                                </div>
                            </div>
                            <div className="inside-items">
                                <img src="\assets\dopgrant\review.svg" alt="img" className="img-fluid" />
                                <div className="text">
                                    <h6>Review Process:</h6>
                                    <p>Our team will carefully review and evaluate all submitted proposals based on the eligibility criteria and the potential impact of your project.                                </p>
                                </div>
                            </div>
                            <div className="inside-items">
                                <img src="\assets\dopgrant\funcing.svg" alt="img" className="img-fluid" />
                                <div className="text">
                                    <h6>Funding Decision:</h6>
                                    <p>Successful applicants will be notified and provided with further instructions regarding the grant agreement and funding disbursement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="supportandresource-sec">
                <div className="parent" style={{ gridTemplateColumns: "1fr" }}>
                    <div className="left-side">
                        <div className="main-heading">
                            <h6>Support and Resources</h6>
                            <p>In addition to financial support, the DOP Grant Program will provide grantees with access to various resources, including:</p>
                        </div>
                        <div className="inside-points">
                            <div className="single-point">
                                <h6>01.</h6>
                                <p>Technical guidance and mentorship from our experienced team</p>
                            </div>
                            <div className="single-point">
                                <h6>02.</h6>
                                <p>Networking opportunities within the DOP community</p>
                            </div>
                            <div className="single-point">
                                <h6>03.</h6>
                                <p>Promotional support and visibility through our channels</p>
                            </div>
                            <div className="single-point">
                                <h6>04.</h6>
                                <p>Access to educational materials and documentation</p>
                            </div>
                        </div>
                        <p className="para">We encourage all interested parties to explore our grant program and contribute to the development of a more secure, transparent, and user-centric data ownership ecosystem.</p>
                    </div>
                    {/* <div className="right-side">
                        <div className="main-heading">
                            <h6>Apply for a grant</h6>
                        </div>
                        <div className="inside-form">
                            <div className="option-field">
                                <input value={name} onChange={(e) => {setName(e.target.value); setNameErr("")}} type="text" placeholder="Full Name" />
                            </div>
                            <p className="text-danger mb-2"> {nameerr}</p>
                            <div className="option-field">
                                <div class="dropdown">
                                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {country ? country : "Select Country"}{" "} <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none">
                                            <path d="M9.70174 0.679993H5.10414H0.964079C0.25562 0.679993 -0.0986098 1.53605 0.403216 2.03787L4.22594 5.8606C4.83847 6.47312 5.83474 6.47312 6.44726 5.8606L7.90108 4.40678L10.27 2.03787C10.7644 1.53605 10.4102 0.679993 9.70174 0.679993Z" fill="white" />
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a onClick={() => handleCountrySelect("usa")} class="dropdown-item">usa</a></li>
                                    </ul>
                                </div>
                                <p className="text-danger">{countryerr}</p>
                            </div>
                            <div className="option-field">
                                <input value={email} onChange={(e) =>{ setEmail(e.target.value);setEmailErr("")}} type="text" placeholder="Email" />
                            </div>
                            <p className="text-danger mb-2"> {emailerr}</p>
                            <label htmlFor="upload" className="upload-div">
                                <img src={documentUrl || "assets/dopgrant/upload-icon.svg"} alt="img" className="img-fluid" />
                                <h6>Upload project summary</h6>
                                <p>15MB (PDF,JPEG,PNG)</p>
                                <input ref={fileInputRef} onChange={handleImageChange} type="file" className="d-none" id="upload" />
                            </label>
                            <p className="text-danger mb-2"> {documentUrlerr}</p>
                            <button onClick={getGrantForm} className="btn-launch w-100 set-btn-white">
                                <h6>Submit</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                    <path d="M9.2085 6.16665L12.0418 8.99998L9.2085 11.8333M4.9585 6.16665L7.79183 8.99998L4.9585 11.8333" stroke="#0B0B0B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </div>
                    </div> */}
                </div>
            </section>
            <AppOpeningSoon />


            <Footer />


            <Modal
                className="contact-modal"
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Applied for grant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        src="\assets\formsubmitted-icon.svg"
                        alt="img"
                        className="img-fluid"
                    />
                    <h6 style={{ fontSize: "26px" }}>You details have successfully Submitted</h6>
                    <p>Someone from our team will get in touch with you shortly</p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Grant;
