import React from "react";
import "./faqs.scss";
import Accordion from 'react-bootstrap/Accordion';
const Faqs = () => {
  return (
    <>
      <section className="mainfaqs">
        <div className="dopphasesheader">
          <p className="dopphasesheaderpara">
            <span>01.</span>
            <span>FAQs</span>
          </p>
        </div>
        <div className="upperhead">
          <h2 className="headinggggg">For further assistance, we have collected and answered the most frequently asked questions regarding DOP:</h2>

        </div>
        <div className="acc">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header> <p><span>01.</span> What is DOP?</p></Accordion.Header>
              <Accordion.Body>
                DOP, short for Data Ownership Protocol, aims to redefine paradigms by enabling user-owned data, empowering users to selectively disclose their on-chain activities. Leveraging ZK-SNARKs and ECDSA, DOP allows users to curate the information they share regarding their asset holdings and transactions while maintaining interoperability with Ethereum DApps and liquidity.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header> <p><span>02.</span> Why is Data Ownership important in blockchain systems according to DOP?</p></Accordion.Header>
              <Accordion.Body>
                DOP believes Data Ownership is crucial due to potential monitoring in authoritarian states, risks of donating to politically sensitive causes, and the threat of hackers targeting crypto wallets.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header> <p><span>03.</span> How does DOP address the issue of Data Ownership in blockchain systems?</p></Accordion.Header>
              <Accordion.Body>
                DOP introduces the concept of user-controlled selective disclosure of on-chain data. Users can choose to disclose certain account information and transaction details while keeping other data points private. This empowers users with control over their data exposure, facilitating wider blockchain adoption by those valuing financial data ownership.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header> <p><span>04.</span> What is "selective transparency" according to DOP?</p></Accordion.Header>
              <Accordion.Body>
                "Selective transparency" allows users to share data with specific counterparties while keeping it hidden from the general public. This feature enables users to maintain privacy without sacrificing accountability and ethics.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header> <p><span>05.</span> What are the key features of the DOP protocol?</p></Accordion.Header>
              <Accordion.Body>
                DOP supports ERC20, ERC721, and ERC1155 token standards, allowing users to selectively disclose their token holdings and transaction histories. It also enables secure interaction with Ethereum DApps, internal ecosystem development, and integration with third-party wallets.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header> <p><span>06.</span> How does DOP handle transaction?</p></Accordion.Header>
              <Accordion.Body>
                DOP utilizes zero-knowledge cryptography for off-chain transactions. Transactions are processed away from the main Ethereum network, reducing congestion and ensuring confidentiality.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header> <p><span>07.</span> What is the governance structure of DOP?</p></Accordion.Header>
              <Accordion.Body>
                DOP will be governed by a decentralized autonomous organization (DAO) with a rotating committee of node operators. This governance model aims to maintain platform security and honesty, placing governance in the hands of users.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header> <p><span>08.</span> How does DOP ensure compatibility with existing user habits and preferences?</p></Accordion.Header>
              <Accordion.Body>
                DOP allows seamless integration with a variety of third-party wallets through open APIs and libraries. This ensures users can maintain familiarity with their preferred wallets while benefiting from enhanced privacy features.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header> <p><span>09.</span> What are the future ambitions of DOP?</p></Accordion.Header>
              <Accordion.Body>
                DOP aims to create an internal ecosystem where developers can build decentralized applications (dApps) with privacy as a core feature. This ecosystem could include decentralized exchanges, liquidity pools, and more, fostering a self-contained environment prioritizing data ownership.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
    </>
  );
};

export default Faqs;
