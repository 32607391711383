import React, { useEffect, useRef } from 'react'
import "./contactbanner.scss"
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all'; 
gsap.registerPlugin(ScrollTrigger);


const Contactbanner = ({firstSectionRef}) => {

  const headingRef = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split('');

     
      ref.current.innerHTML = '';

      
      chars.forEach(char => {
        const charSpan = document.createElement('span');
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });

   
      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.1,
        duration: speed 
      });

      gsap.to(ref.current.children, {
        color: '#fff',
        stagger: 0.1,
        duration: speed 
      });
    };

    animateText(headingRef, 0.1); 
    // animateText(btn1Ref, 0.1);  
  }, []);


  // different examples of 
  // const headingRef1 = useRef(null);
  // const headingRef2 = useRef(null);
  const paragraphRef1 = useRef(null);
  // const paragraphRef2 = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed, animationType) => {
      switch (animationType) {
        case 'fadeIn':
          gsap.from(ref.current, {
            opacity: 0,
            duration: speed
          });
          break;
        case 'slideUp':
          gsap.from(ref.current, {
            y: 100,
            opacity: 0,
            duration: speed
          });
          break;
        case 'scale':
          gsap.from(ref.current, {
            scale: 0,
            opacity: 0,
            duration: speed
          });
          break;
        default:
          break;
      }
    };

    // animateText(headingRef1, 0.5, 'fadeIn');
    // animateText(headingRef2, 1, 'slideUp');
    animateText(paragraphRef1, 2, 'scale');
    // animateText(paragraphRef2, 5, 'fadeIn');
  }, []);
  return (
    <>
    <section className='maincontact' ref={firstSectionRef}>
    <div className="custom-container">
          <div className="techbannerinner">
            <h1 className="techhead" ref={paragraphRef1}><span ref={headingRef}>Contact</span></h1>
          </div>
        </div>
    </section>
    </>
  )
}

export default Contactbanner