import React from 'react'
import './dopphases.scss'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



const Dopphases = () => {
    // const [currentSection, setCurrentSection] = useState(1);

    // useEffect(() => {
    //   const sectionLoop = setInterval(() => {
    //     setCurrentSection(prevSection => (prevSection % 3) + 1);
    //   }, 5000);

    //   return () => clearInterval(sectionLoop);
    // }, []);

    // const [currentSection, setCurrentSection] = useState(1);
    // const [startCycling, setStartCycling] = useState(false);
    // const sectionRef = useRef();

    // useEffect(() => {
    //   const observer = new IntersectionObserver(entries => {
    //     entries.forEach(entry => {
    //       if (entry.isIntersecting && entry.target === sectionRef.current && !startCycling) {
    //         setStartCycling(true);
    //       }
    //     });
    //   }, { threshold: 0.5 });

    //   observer.observe(sectionRef.current);

    //   return () => observer.disconnect();
    // }, []);

    // useEffect(() => {
    //   if (startCycling) {
    //     const sectionLoop = setInterval(() => {
    //       setCurrentSection(prevSection => {
    //         return prevSection === 3 ? 1 : prevSection + 1;
    //       });
    //     }, 5000);

    //     return () => clearInterval(sectionLoop);
    //   }
    // }, [startCycling]);


    return (
        <>
            <section className="dopphases">
                <div className="dopphasesheader">
                    <p className="dopphasesheaderpara">
                        <span>03.</span>
                        <span>DOP Phases</span>
                    </p>
                </div>
                <div className="custom-container">
                    <div className="main-heading">
                        <h4>Foreseeable phases of DOP'S Mainnet technology</h4>
                    </div>
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className='tab-phases'
                    >
                        <Tab eventKey="home" title="Phase 1">
                            <div className="dopphasesinner">
                                <div className="dopphasesleft">
                                    <h4 className="dopphaseslefthead">Phase 1 - you are here</h4>
                                    <p className="dopphasesleftpara">Three key aspects: encrypting, sending, and decrypting. Encryption is your gateway into the system. While on the public Ethereum blockchain, your data is visible. Encryption allows the system to securely store your information and funds.</p>
                                    <p className="dopphasesleftpara">With the 'Send' feature, you can freely interact within the protocol, assured that your data, assets, and identity are protected. If you need to withdraw data or funds, use the 'Decrypt' feature, which securely transfers your data and funds back onto the public blockchain, exiting the system.</p>
                                </div>
                                <div className="dopphasesrightimg">
                                    <img src="\assets\tech\phaseone.png" alt="dopphasesrightinnerimg" className="dopphasesrightinnerimg img-fluid desktopphaseimg" />
                                    <img src="\assets\tech\phaseonembl.png" alt="dopphasesrightinnerimg" className="d-none mobilephaseimg img-fluid" />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Phase 2">
                            <div className="dopphasesinner">
                                <div className="dopphasesleft">
                                    <h4 className="dopphaseslefthead">Phase 2</h4>
                                    <p className="dopphasesleftpara">Users will have the ability to generate special proofs to selectively disclose specific balances or tokens when interacting with external wallets and addresses.</p>

                                </div>
                                <div className="dopphasesrightimg">
                                    <img src="\assets\tech\phasetwo.png" alt="dopphasesrightinnerimg" className="dopphasesrightinnerimg img-fluid desktopphaseimg" />
                                    <img src="\assets\tech\phasetwombl.png" alt="dopphasesrightinnerimg" className="d-none mobilephaseimg img-fluid" />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Phase 3">
                            <div className="dopphasesinner">
                                <div className="dopphasesleft">
                                    <h4 className="dopphaseslefthead">Phase 3</h4>
                                    <p className="dopphasesleftpara">Users will have the ability to make NFTs visible, encrypt them, and generate proofs for specific NFTs within a selective collection.</p>
                                    <p className="dopphasesleftpara">This selective disclosure will be available on DOP scan, where users can manage public profiles, choosing what data to reveal and what to keep private.</p>
                                </div>
                                <div className="dopphasesrightimg">
                                    <img src="\assets\tech\phasethree.png" alt="dopphasesrightinnerimg" className="dopphasesrightinnerimg img-fluid desktopphaseimg" />
                                    <img src="\assets\tech\phasethreembl.png" alt="dopphasesrightinnerimg" className="d-none mobilephaseimg img-fluid" />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>

                </div>
            </section>



        </>
    )
}

export default Dopphases







