import { useEffect, useRef } from 'react';
import '../../App.scss';
import Navbar from '../landing/header/Navbar';
import Communitybanner from './Communitybanner/Communitybanner';
import Communityprojects from './Communityprojects/Communityprojects';
import Dopcommunity from './Dopcommunity/Dopcommunity';
import Read from './Read/Read';
import Communityactivity from './Communityactivity/Communityactivity';
import Footer from '../landing/footer/Footer';
import gsap from 'gsap';
import DopSocial from './DopSocial/DopSocial';

function Communityleaders() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);


  useEffect(() => {
    const isLargeScreen = window.matchMedia("(min-width: 1500px)").matches;
    const isMobileScreen = window.matchMedia("(max-width: 600px)").matches;

    gsap.to(firstSectionRef.current, {
        scrollTrigger: {
            trigger: secondSectionRef.current,
            start: isMobileScreen ? 'top 40%' : (isLargeScreen ? 'top 35%' : 'top 40%'),
            end: isMobileScreen ? 'bottom top' : (isLargeScreen ? 'bottom top' : 'bottom top'),
            scrub: true,
        },
        filter: 'blur(40px)',
    });
}, []);


  return (
    <>
      <Navbar />
      <Communitybanner firstSectionRef={firstSectionRef} />
      <Communityprojects secondSectionRef={secondSectionRef} />
      <Dopcommunity />
      <Communityactivity />
      <DopSocial />
      <Read />
      <Footer />
    </>
  );
}

export default Communityleaders;