import React from 'react'
import './communityprojects.scss'

const Communityprojects = ({secondSectionRef}) => {
    return (
        <>
            <section className="communitycards" ref={secondSectionRef}>
                <div className="communitycardsheader">
                    <p className="communitycardsheaderpara">
                        <span>01.</span>
                        <span>Community Leaders</span>
                    </p>
                </div>
                <div className="custom-container">
                    <div className="communitycardstop">
                        <p className="communitycardstopleft">DOP is a community-driven project at its core</p>
                        <p className="communitycardstopright">Building a large and engaged holder & follower community is a crucial part of DOP's architecture, and all the way we remain committed to our community-driven approach as we continue to grow and develop our protocol.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Communityprojects