import React, { useEffect, useRef } from 'react'
import './read.scss'
import Lottie from "lottie-web";
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';

const Read = () => {
    const containerRef = useRef(null);

  useEffect(() => {
      const animation = Lottie.loadAnimation({
          container: containerRef.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: '/circular-animation.json',
      });

      return () => {
          animation.destroy();
      };
  }, []);


  const btnRef = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split('');
      
      ref.current.innerHTML = '';
      chars.forEach(char => {
        const charSpan = document.createElement('span');
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });
      
      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.03,
        duration: speed 
      });
  
      gsap.to(ref.current.children, {
        color: '#fff',
        stagger: 0.03,
        duration: speed 
      });
    };
  
    const btn = btnRef.current;
  
    const handleMouseEnter = () => {
      animateText(btnRef, 0.2);
    };
  
    const handleMouseLeave = () => {
      btnRef.current.innerHTML = btn.innerText;
    };
  
    btn.addEventListener('mouseenter', handleMouseEnter);
    btn.addEventListener('mouseleave', handleMouseLeave);
  
    return () => {
      btn.removeEventListener('mouseenter', handleMouseEnter);
      btn.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);
  
    return (
        <>
            <section className="mainread">
                {/* <img src="\assets\tech\joinussvg.svg" alt="joinussvg" className="joinussvg" /> */}
                <div className="testimonial-lottie-1 is-margin-bottom stats-bg joinussvg" ref={containerRef}></div>
                <div className="custom-container">
                    <div className="mainheadingsss">
                        <h2 className="readhead">
                            Why read, when you can lead?
                        </h2>
                        <p className="readpara">Pitch your new DOP community ideas</p>
                        <Link to="/contact" className="btn-launch">
                            <h6 ref={btnRef}>Get In Touch</h6>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                            >
                                <path
                                    d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333"
                                    stroke="white"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Read