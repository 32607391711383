import React from 'react'
import "./dopsocial.scss"

const DopSocial = () => {
  return (
    <>
      <section className="dop-social-section">
        <div className="custom-container">
            <div className="parent">
                <div className="left-side">
                    <div className="main-heading">
                        <h6>DOP’s Socials</h6>
                    </div>
                </div>
                <div className="right-side">
                    <div className="social-icons">
                        <a href="https://twitter.com/dop_org" target='_blank'><img src="\assets\community\social-icons\twiter.svg" alt="img" className='img-fluid' /></a>
                        <a href="https://www.instagram.com/data_ownership_protocol" target='_blank'><img src="\assets\community\social-icons\insta.svg" alt="img" className='img-fluid' /></a>
                        <a href="https://www.youtube.com/@DataOwnershipProtocol" target='_blank'><img src="\assets\community\social-icons\youtube.svg" alt="img" className='img-fluid' /></a>
                        <a href="https://www.linkedin.com/company/dop-org" target='_blank'><img src="\assets\community\social-icons\linkedin.svg" alt="img" className='img-fluid' /></a>
                        <a href="https://medium.com/dop-org" target='_blank'><img src="\assets\community\social-icons\medium.svg" alt="img" className='img-fluid' /></a>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default DopSocial
