import '../../App.scss';
import React, { useEffect, useRef } from "react";
import Navbar from '../landing/header/Navbar';
import Contactbanner from './Contactbanner/Contactbanner';
import Getintouch from './Getintouch/Getintouch';
import Faqs from './Getintouch/Faqs/Faqs';
import Footer from '../landing/footer/Footer';
import gsap from 'gsap';

function Contact() {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    const firstSectionRef = useRef(null);
    const secondSectionRef = useRef(null);
  
    useEffect(() => {
      const isLargeScreen = window.matchMedia("(min-width: 1500px)").matches;
      const isMobileScreen = window.matchMedia("(max-width: 600px)").matches;
  
      gsap.to(firstSectionRef.current, {
          scrollTrigger: {
              trigger: secondSectionRef.current,
              start: isMobileScreen ? 'top 40%' : (isLargeScreen ? 'top 35%' : 'top 40%'),
              end: isMobileScreen ? 'bottom top' : (isLargeScreen ? 'bottom top' : 'bottom top'),
              scrub: true,
          },
          filter: 'blur(40px)',
      });
  }, []);

  
  return (
    <>
    <Navbar />
    <Contactbanner firstSectionRef={firstSectionRef} />
    <Getintouch secondSectionRef={secondSectionRef} />
    <Faqs />
    <Footer />
    </>
  )
}

export default Contact