import '../../App.scss';
import React, { useEffect, useRef } from "react";
import Techbanner from './Techbanner/Techbanner';
import Navbar from '../landing/header/Navbar';
import Techcards from './Techcards/Techcards';
import Datacards from './Datacards/Datacards';
import Dopphases from './Dopphases/Dopphases';
import Joinus from './Joinus/Joinus';
import Footer from '../landing/footer/Footer';
import { gsap } from 'gsap';

function Tech() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);


  useEffect(() => {
    const isLargeScreen = window.matchMedia("(min-width: 1500px)").matches;
    const isMobileScreen = window.matchMedia("(max-width: 600px)").matches;

    gsap.to(firstSectionRef.current, {
        scrollTrigger: {
            trigger: secondSectionRef.current,
            start: isMobileScreen ? 'top 40%' : (isLargeScreen ? 'top 35%' : 'top 40%'),
            end: isMobileScreen ? 'bottom top' : (isLargeScreen ? 'bottom top' : 'bottom top'),
            scrub: true,
        },
        filter: 'blur(40px)',
    });
}, []);


  return (
    <>
      <Navbar />
      <Techbanner firstSectionRef={firstSectionRef} />
      <Techcards secondSectionRef={secondSectionRef} />
      <Datacards />
      <Dopphases />
      <Joinus />
      <Footer />
    </>
  );
}

export default Tech;