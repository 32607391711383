import React, { useEffect, useRef } from "react";
import "./visionbanner.scss"
import Lottie from "lottie-web";
const Visioncard = ({secondSectionRef}) => {
  const containerRef = useRef(null);

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: containerRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/eye-animation.json',
        });

        return () => {
            animation.destroy();
        };
    }, []);
  return (
    <>
      <section className="visioncard" ref={secondSectionRef}>
        {/* <img src="\assets\Company\eye.png" alt="img" className="img-fluid eye-abs"/> */}
        <div className="testimonial-lottie-1 is-margin-bottom stats-bg eye-abs" ref={containerRef}></div>
        <div className="dopphasesheader">
          <p className="dopphasesheaderpara">
            <span>01.</span>
            <span>the Vision</span>
          </p>
        </div>
        <div className="custom-container">
            <div className="mainparentvision">
                <h3 className="visionhead">DOP aims to empower individuals and businesses with unprecedented control over their data through selective transparency and zero-knowledge cryptography.</h3>
                <p className="visionpara">
                Users will have the ability to manage information about their assets and transactions while maintaining discretion. Through seamless integration and an enhanced user experience, DOP is a catalyst for the widespread adoption of blockchain technologies, empowering users to assert ownership and actively manage their digital presence in the evolving landscape of Web3.
                </p>
            </div>
        </div>
      </section>
    </>
  );
};

export default Visioncard;
