import React from "react";
import "./team.scss";
import Navbar from "../landing/header/Navbar";
import { Link } from "react-router-dom";

const Team = () => {
  return (
    <>
      <Navbar />
      <section className="mainteam">
        <div className="custom-container ">
          <h2 className="mainhead">the team</h2>
        </div>
      </section>
      <section className="theteam">
        <div className="top-left-text">
          <p>01. the team</p>
        </div>
        <div className="custom-container ">
          <div className="parenttext">
            <div className="left">
              <p>
                Behind every groundbreaking project is a dedicated and
                experienced team driving its success.
              </p>
            </div>
            <div className="right">
              <p>
                With a collective wealth of experience spanning various aspects
                of the blockchain industry, our team is committed to driving
                DOP's mission forward.
              </p>
            </div>
          </div>
          <div className="parentcards"></div>
        </div>
      </section>
      <section className="joinus">
        <div className="custom-container ">
            <div className="mainheadingsss">
            <h2 className="joinhead">
            Join us at DOP as we pave the way for mainstream adoption in the
            web3 realm
          </h2>
          <Link to="/contact" className="btn-launch">
            <h6>Get In Touch</h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333"
                stroke="white"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
            </div>
       
        </div>
      </section>
    </>
  );
};

export default Team;
