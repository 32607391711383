import "./App.scss";
import Landing from "./components/landing/Landing.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/landing/footer/Footer.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Team from "./components/Team/Team.js";
import Company from "./components/Company/Company.js";
import Tech from "./components/Tech/Tech.js";
import Visionmain from "./components/Visionmain.js";
import Hub from "./components/Hub/Hub.js";
import Blog from "./components/Blog/Blog.js";
import Communityleaders from "./components/Communityleaders/Communityleaders.js";
import Dopcreators from "./components/Dopcreators/Dopcreators.js";
import Contact from "./components/Contact/Contact.js";
import Blogdetail from "./components/Blog/Blogdetail.js";
import Ambassador from "./components/Ambassador/Ambassador.js";
import Privacy from "./components/Privacypolicy/Privacy.js";
import Termofuse from "./components/Privacypolicy/Termofuse.js";
import PlanNew from "./components/Plan/PlanNew.js";
import Grant from "./components/Grant/Grant.js";
import ApplyForDOP from "./components/Applyfordopgrantprogram/ApplyForDOP.js";
import Grantprogramterms from "./components/Privacypolicy/Grantprogramterms.js";

function App() {
  return (
    <>
       <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      style={{ fontSize: '16px', zIndex: "9999999999999999" }} 
      draggable
      pauseOnHover
      theme="colored"
    />
      <Router>
        {/* <Navbar/> */}
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/company" element={<Company />} />
          <Route exact path="/tech" element={<Tech />} />
          <Route exact path="/vision" element={<Visionmain />} />
          <Route exact path="/hub" element={<Hub />} />
          <Route exact path="/plan" element={<PlanNew />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/community" element={<Communityleaders />} />
          <Route exact path="/dopcreator" element={<Dopcreators />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/ambassador" element={<Ambassador />} />
          <Route exact path="/blog/:id" element={<Blogdetail />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Termofuse />} />
          <Route exact path="/dopgrant" element={<Grant />} />
          <Route exact path="/apply" element={<ApplyForDOP />} />
          <Route exact path="/grantprogramterms" element={<Grantprogramterms />} />
        </Routes>
        {/* <Footer/> */}
        {/* new build */}
      </Router>
    </>
  );
}

export default App;
