import React, { useEffect, useState } from 'react'
import './privacy.scss'
import Navbar from '../landing/header/Navbar'
import Footer from '../landing/footer/Footer'

const Termofuse = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
        <Navbar />
        <section className="termofuse-section">
        <div className="custom-container">
          <div className="parent">
            <h4>Terms of Service</h4>
            <p className="last-update">Last Updated: March 21, 2024</p>
            <p className="para">DOP Ltd (in formation) (“DOP,” “we,” “us,” or “our”), currently provides a method of access to, and certain information about, the Data Ownership Protocol, a layer-2 protocol that scales Ethereum, accessible here: <a href="https://app.dop.org/" target='_blank'>https://app.dop.org/</a> (the “Protocol”) as well as related content and functionality about DOP through a website located at <a href="https://dop.org/" target='_blank'>https://dop.org/</a> (the “Site”). We also currently provide access and information about DOP through our related technologies, including all of our existing and any updated or new features, functionalities and technologies (collectively, the “Service”).</p>
            <p className='para'>DOP and the suite of open-source code underlying it are contributed, used, and supported by a wide variety of participants. DOP is one of these many participants and contributes to the DOP ecosystem as a service provider to the Data Ownership Foundation, a Panamanian Foundation company. To facilitate the early-stage development of the DOP ecosystem, DOP currently operates the Site. DOP expects to transition the maintenance and operation of the Site to one or more separate entities that are part of the DOP community in due course.</p>
            <p className="para">These Terms and Conditions (these <span>“Terms”</span> ) set forth the legally binding terms and conditions that govern your use of the Site. Please note that certain parts and features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such parts and features. All such additional terms, guidelines, and rules, including but not limited to the Privacy Policy, and the Cookies Policy, are incorporated by reference into these Terms. By accessing or using the Site, you are accepting these Terms (on behalf of yourself or the entity that you represent), and you represent and warrant that you have the willingness, right, authority, and capacity to enter into these Terms (on behalf of yourself or the entity that you represent).</p>
            <h6>IMPORTANT DISCLAIMERS.</h6>
            <p className="para">THE SITE IS PROVIDED BY DOP ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT AVAILABILITY, ACCESSIBILITY, OR USE OF THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE.</p>
            <p className="para">We make no representation, warranty, guarantee, or undertaking regarding the Protocol or any of its products, services, or activities, whether express or implied, including but not limited to warranties of compliance, accuracy, reliability, validity, merchantability, fitness for a particular purpose, quality, availability, durability, and noninfringement, or as to any of it being uninterrupted, error free, free of harmful components, or secure.</p>
            <p className="para">We are not liable for, or in connection with, any actions, proceedings, claims, damages, expenses or other liabilities, whether in an action of contract, tort or otherwise, arising from, related to or in connection with the Protocol or any of its products, services, or activities, or the use or dealings in or with any of them or in reliance thereon.</p>
            <p className="para">We are not, do not speak for, and cannot bind, the Protocol and/or the DOP community. The DOP community represents a wider network of independent contributors to the Protocol. DOP is a separate, independent entity. While we currently contribute to the Protocol and the larger DOP ecosystem, we do not speak for the community, and cannot contractually bind it in any manner.</p>
            <p className="para">We do not endorse third parties or third party information. Any time we link to, quote or otherwise reference a third party or reproduce or incorporate their information, content or material, it is solely for informational purposes. You should not assume that we have verified the accuracy of, or endorse in any way, such information, content or materials. When we talk about future ideas or prospects, we are expressing our vision and hopes, and there is no commitment or guarantee that it will come true, that we will implement any of it, or that it will work.</p>
            <h6>The Site and Protocol.</h6>
            <p className="para"> <span>Access to the Site.</span> You may only access or use the Site or accept the Terms if you are an individual of legal age to form a binding contract (or if not, you have received your parent’s or guardian’s permission to use the Site and have gotten your parent or guardian to agree to the Terms on your behalf). If you are agreeing to the Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to the Terms on that organization’s or entity’s behalf and bind them to the Terms (in which case, the references to “you” and “your” in the Terms, except for in this sentence and in the paragraph directly above, refer to that organization or entity).</p>
            <p className="para"> <span>License.</span> Subject to these Terms, you are hereby granted a non-transferable, non-exclusive, revocable, limited license to use and access the Site and the Services solely for your own personal, non-commercial use, subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site; and (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site. All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.</p>
            <p className="para"> <span>Functionality.</span> The Site facilitates your ability to access the Protocol, a layer 2 on the Ethereum blockchain that utilizes zero-knowledge proofs to ensure adaptable transparency . The Protocol is public, permissionless, and runs on open-source self-executing smart contracts. The Protocol enables people to do various things, such as processing Ethereum transactions more efficiently and interacting with smart contract-based applications ( <span>“dApps”</span> ) to securely store and transact assets while maintaining control over their data, providing a means to selectively disclose token holdings and transaction histories. The Protocol supports private transactions involving any ERC-20 token or NFT via a 0zk address system, where asset details remain hidden until users choose to decrypt them back to public addresses. </p>
            <p className="para"> <span>Accessing the Protocol.</span> To access the Protocol you must use non-custodial wallet software (a “ <span>Web3 wallet</span> ”), which constructs and broadcasts the data (“transactions”) that allows you to interact with the Protocol. By using your Web3 wallet in connection with accessing the Protocol, you agree that you are using the Web3 wallet under the terms and conditions of the applicable provider of the Web3 wallet, if relevant. We do not have custody or control over the contents of your Web3 wallet and we have no ability to retrieve or transfer its contents. </p>
            <h6>Compliance with Law.</h6>
            <p className="para">You represent and warrant that you will comply with all laws that apply to you, your use of the Site and/or the Protocol, and your actions and omissions that relate to the Protocol. If your use of the Protocol is prohibited by applicable laws, then you aren’t authorized to use the Site and/or the Protocol. We will not be responsible for your using the Site and/or the Protocol in a way that is a violation of any law.</p>
            <p className="para">Without limiting the foregoing, you represent and warrant that you are not, and for the duration of the time you use the Site and/or the Protocol will not be (a) a citizen, resident, or organized in a jurisdiction or territory that is the subject of comprehensive country-wide, territory-wide, or regional economic sanctions regulations, embargoes, or restrictive measures (the “Sanctions”), by the United Nations, the United States of America, the European Union or any Member State thereof, the United Kingdom or the respective Governmental Authorities of any of the foregoing responsible for administering, enacting or enforcing Sanctions, including without limitation, OFAC, the US Department of State or the United Kingdom Treasury (the “<span>Sanctions Authority</span>”) or (b) the subject of economic or trade sanctions administered or enforced by any governmental authority or otherwise designated on any list of prohibited or restricted parties, as updated for time to time (including but not limited to the the Consolidated United Nations Security Council Sanctions List; the Specially Designated Nationals and Blocked Persons List or the Sectoral Sanctions Identification List maintained by the US Office of Foreign Assets Control (OFAC); the Consolidated List of Persons, Groups and Entities subject to EU Financial Sanctions; the Consolidated List of Financial Sanctions Targets or List of persons subject to restrictive measures in view of Russia's actions destabilizing the situation in Ukraine, maintained by the United Kingdom Treasury or any similar list maintained by, or public announcement of sanctions made by, any other Sanctions Authority). </p>
            <p className="para">Furthermore, you are not located in, resident in, or incorporated under the laws of Afghanistan, Belarus, Burundi, Central African Republic, Congo (the Democratic Republic of the Congo), Cuba, Guinea, Guinea-Bissau, Haiti, Hong Kong, Iran, Iraq, the Democratic People's Republic of Korea (North Korea), Lebanon, Libya, Mali, Myanmar (Burma), Nicaragua, Somalia, South Sudan, Sudan, Syria, Taiwan, Venezuela, Western Sahara, Yemen, Zimbabwe; the Krim, Donetsk, Kherson and Luhansk regions of Ukraine, the United States of America or any jurisdiction which is the target of such comprehensive, country-wide or territory-wide Sanctions as currently apply to the aforementioned countries or any country or territory that has been designated as non-cooperative with international anti-money laundering principles or procedures by an intergovernmental group or organization, such as the Financial Action Task Force on Money Laundering (“ <span>FATF</span> ”) (each, a “ <span>Restricted Jurisdiction</span> ”)</p>
            <p className="para">You are not, and have not been involved in any type of activity associated with money laundering or terror financing, nor violated any provision of the U.S. Foreign Corrupt Practices Act of 1977, as amended, the UK Bribery Act (2010), or any other applicable anti-corruption or anti bribery legislation, nor were you ever subject, to any investigation by, or have received a request for information from any governmental body relating to corruption or bribery under any statute and you hereby consent to DOP running any checks or enquiries with third parties and you waive any privacy or other right in connection therewith and acknowledge that any breach of this representation by you will entitle DOP to terminate these Terms with immediate effect. </p>
            <p className="para">Neither you, nor any of your affiliates, shareholders, direct or indirect owners, or beneficiaries is owned or controlled by, or acting on behalf of or for the benefit of, any person on a Sanctions List or who is a citizen, resident, or incorporated in any Restricted Jurisdiction.</p>
            <p className="para">If at any point the above is no longer true, you must immediately cease using the Site and/or the Protocol.</p>
            <h6>Assumption of Risk.</h6>
            <p className="para">By using the Protocol, you (a) represent that you are sophisticated enough to understand the various inherent risks of using cryptographic and public blockchain-based systems, including but not limited to the Protocol, and digital assets, and (b) acknowledge and accept all such risks, and agree that we make no representations or warranties (expressly or implicitly) regarding, and that you will not hold us liable for those risks, including but not limited to the risks described below, any or all of which could lead to losses and damages, including the total and irrevocable loss of your assets.</p>
            <p className="para">These risks include, but are not limited to:</p>
            <ul>
              <li><span>Wallet security and safekeeping.</span> You are solely responsible for the safeguarding and security of your Web3 wallets. If you lose your wallet seed phrase, private keys, or password, you may be forever unable to access your digital assets. Any unauthorized access to your wallet by third parties could result in the loss or theft of your digital assets. We have no involvement in, or responsibility for, storing, retaining, securing or recovering your Web3 wallet seed phrases, private keys, or passwords, or for any unauthorized access to your Web3 wallet.
              </li>
              <li> <span>Blockchain technology.</span> Public blockchains, and the technology underlying and interacting with cryptographic and public blockchain-based systems, are experimental, inherently risky, and subject to change. Among other risks, bugs, malfunctions, cyberattacks, or changes to a particular public blockchain (e.g., via forks) could disrupt these technologies irreparably. There is no guarantee that any of these technologies will not become unavailable, degraded, or subject to hardware or software errors, operational or technical difficulties, denial-of-service attacks, other cyberattacks, or other problems requiring maintenance, interruptions, delays, or errors.
              </li>
              <li> <span>Network cost and performance.</span> The cost, speed, and availability of transacting on public blockchain systems are subject to significant variability. There is no guarantee that any transfer will be confirmed or transferred successfully.</li>
              <li> <span>Blockchain transactions and smart contract execution.</span> Public blockchain-based transactions (including but not limited to transactions automatically executed by smart contracts) are generally considered irreversible when confirmed. Any transaction that will interact with smart contracts or be recorded on a public blockchain must be recorded with extreme caution.</li>
              <li> <span>Digital assets.</span> The markets for digital assets are nascent and highly volatile due to various risk factors including (but not limited to) adoption, speculation, technology, security, and regulation. Digital assets and their underlying blockchain networks are complex emerging technologies that may be subject to delays, halts or go offline as a result of errors, forks, attacks or other unforeseeable reasons. Anyone can create a digital asset, including fake versions of existing digital assets and digital assets that falsely claim to represent projects. So-called stablecoins may not be as stable as they purport to be, may not be fully or adequately collateralized, and may be subject to panics and runs. You are solely responsible for understanding the risks specific to each digital asset that is relevant to you.
              </li>
              <li> <span>Control of the Protocol.</span> The Protocol is subject to periodic upgrades, which may introduce other risks, bugs, malfunctions, cyberattack vectors, or other changes to the Protocol that could disrupt its operation or functionality, or otherwise cause you damage or loss.</li>
              <li> <span>Third Party Risks.</span> Third-party products carry their own individual, oftentimes highly significant risks. When you use the Protocol to interact with any third-party products, you are subject to all of those risks.</li>
              <li> <span>Legislative and regulatory risks.</span> Legislative and regulatory risks. Digital assets, blockchain technology, and any related software and services are subject to legal and regulatory uncertainty in many jurisdictions. Legislative and regulatory changes or actions may adversely affect the usage, transferability, transactability and accessibility of your digital assets or the Protocol.</li>
            </ul>
            <p className="para"> <span>Release of claims.</span> You expressly agree that you assume all risks in connection with your interaction with the Protocol. You further expressly waive and release DOP, as well as its affiliates and service providers, and each of their respective past, present and future officers, directors, members, employees, consultants, representatives and agents, and each of their respective successors and assigns from any and all liability, claims, causes of action, or damages arising from or in any way relating to your interaction with the Protocol.
            </p>
            <h6>DOP Token Staking Platform.</h6>
            <p className='para'><span>Binding Effect.</span> By participating in the DOP token staking program, you agree to be bound by these terms and conditions and assume all risks associated with staking your DOP tokens.
            </p>

            <p className='para'><span>Staking and Rewards.</span> By staking your DOP tokens on our staking platform, available at <a href="https://staking.dop.org/" target='_blank'>https://staking.dop.org/</a> (the <span className='bold-text'>“Platform”</span>), you will begin accruing rewards immediately. The rewards are calculated based on the current Annual Percentage Yield (APY), which is determined by the total amount of DOP tokens staked in the system. During the staking period, your tokens will be held on a smart contract and will not be available for any use by you (including transfer, swap, payment as fees or any other use) until unstaked and returned to your wallet. We specifically note that by staking your DOP tokens you will be exposed to fluctuations of the DOP token price compared to other tokens, cryptographic coins or fiat currencies during the staking term (including the lockage period set forth in Section 3 below). Please note that the staking and the unstaking are subject to payment of certain third-party fees such as network or gas fees, as charged by your wallet or the applicable blockchain network.
            </p>

            <p className="para"><span>Unstaking.</span> You may unstake your DOP tokens at any time. However, please note that unstaked tokens will be subject to a mandatory 7-day waiting period before they are released to your wallet. During this 7-day period, your unstaked tokens will not earn any rewards.
            </p>

            <p className="para"><span>Reward Claims.</span> Staking rewards can only be claimed after a 90-day lockup period, which starts from the initial staking date. This means that you must wait 90 days from the date you first staked your tokens before you can claim any rewards earned during that period.
            </p>

            <p className="para"><span>Reward Restaking.</span> If you choose to restake your earned rewards, they will be subject to a new 90-day lockup period. By restaking rewards, you can compound your earnings over time.
            </p>

            <p className="para"><span>APY Fluctuations.</span> The APY for staking DOP tokens is not fixed and can change at any time. The APY depends on the total amount of DOP tokens staked in the system. As more tokens are staked, the APY may decrease. Conversely, if fewer tokens are staked, the APY may increase. For example, if the current APY is 200% and the total amount of staked tokens doubles, the APY may drop to 100%. The APY applicable in any given moment is stated in the Platform.
            </p>

            <p className="para"><span>No Guarantee of Rewards.</span> While we strive to provide accurate estimates of staking rewards based on the current APY, we cannot guarantee any specific reward amount. Actual rewards may vary based on various factors, including fluctuations in the total amount of staked tokens and any changes to the staking program.
            </p>

            <p className="para"><span>Risk of Loss.</span> By staking your DOP tokens, you acknowledge and accept the risk of potential loss due to unforeseen circumstances such as hacks, smart contract vulnerabilities, or other technical issues. We will implement reasonable security measures to protect staked tokens, but we cannot guarantee absolute security. In the event of a hack or other loss of staked tokens, we will not be held responsible or liable for any resulting damages or losses.
            </p>

            <p className="para"><span>User Responsibility.</span> It is your responsibility to understand the terms and conditions of the DOP token staking program before participating. By staking your tokens, you acknowledge that you have read, understood, and agreed to these terms and conditions, including the risk of loss.
            </p>

            <p className="para"> <span>Changes to the Terms.</span> We reserve the right to change these Terms at any time. All changes are effective immediately when we post them. Your continued use of the Site following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
            <p className="para"> <span>Intellectual Property Rights.</span> The Site and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection, and arrangement thereof), are owned by us, our licensors or other providers of such material and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except as indicated otherwise.
            </p>
            <p className="para"> <span>Feedback.</span> To the extent You provide us any feedback, comments or suggestions ("Feedback"), you grant us a royalty-free, fully paid up, worldwide, perpetual and irrevocable license to incorporate the Feedback into the Site, the Protocol, or any current or future products or services.
            </p>
            <p className="para"> <span>Third-Party Materials.</span> The Site contains references, links, and enables you to connect to third-party resources including (but not limited to) Web3 wallets, non-standard bridges, applications, and other information, materials, products, or services, which we do not own or control (collectively, “ <span>third-party products</span> ”). We do not approve, monitor, endorse, make any representations or warranties (expressly or implicitly) or assume any responsibility for any third-party products, any component thereof, or the manner in which those products or components interact with the Site. When you use or rely on any third-party products, you do so at your own risk. You understand that you are solely responsible for any fees or costs associated with using third-party products and that, unless stated herein, the Terms do not otherwise apply to your dealings or relationships with any third parties or third-party products.</p>

            <p className="para"> <span>Prohibited Uses.</span> You may use the Site only for lawful purposes and in accordance with these Terms. You agree not to use the Site: (i) in any way that violates any applicable federal, state, local, or international law or regulation, including, without limitation, any applicable sanctions laws, export control laws, securities laws, anti-money laundering laws, privacy laws; (ii) use any device, software or routine that interferes with the proper working of any Site; (iii) attempt to probe, scan or test the vulnerability of the Protocol, or otherwise seek to interfere with or compromise the integrity, security, or proper functioning of the Protocol; (iv) attempt to interfere with the proper working of the Site, or interfere with, damage, or disrupt any part of the Site, the server(s) on which the Site is stored, or any server, computer or database connected to the Site; (v) further or promote any criminal activity or enterprise or provide instructional information about illegal activities; or (vi) encourage or enable any other individual to do any of the foregoing.
            </p>
            <p className="para"> <span>Indemnification.</span> To the maximum extent permitted by applicable laws, you agree to indemnify, defend and hold harmless DOP, as well as its affiliates and service providers, and each of their respective past, present and future officers, directors, members, employees, consultants, representatives and agents, and each of their respective successors and assigns (the “ <span>Indemnified Parties</span> ”) from and against all actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations, taxes, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses) and costs (including, without limitation, court costs, costs of settlement and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, “ <span>Claims</span> ”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to: (a) your use of the Site; (b) your violation of these Terms or applicable law; (c) your violation of the rights of a third party; and (d) your negligence or willful misconduct. You agree to promptly notify us of any third party Claims and cooperate with the Indemnified Parties in defending such Claims. You further agree that the Indemnified Parties shall have the right to control the defense or settlement of any third party Claims as they relate to us, if it so chooses.</p>
            <p className="para"> <span>Limitation of Liability.</span> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL THE INDEMNIFIED PARTIES OR ANY OF THEM BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR: (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION; (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY; (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF ONE-HUNDRED ($100) DOLLARS; OR (D) ANY MATTER BEYOND THE REASONABLE CONTROL OF THE INDEMNIFIED PARTIES OR ANY OF THEM.
            </p>

            <h6>Dispute Resolution; Arbitration.</h6>
            <p className="para">This Agreement shall be interpreted, construed and enforced in accordance with the laws of Panama without regard to principles of conflict of laws. Any civil action or legal proceeding arising out of or relating to this Agreement will be brought under the Rules of Arbitration of the International Chamber of Commerce by one arbitrator appointed in accordance with the said Rules. The arbitration shall be held in the English language and the place of arbitration shall be Panama City, Panama.  The arbitrator’s decision will be final and binding upon the Parties.  All arbitration costs and expenses, including the arbitrator’s fees, will be borne equally by the Parties, unless otherwise awarded by the arbitrator in his discretion. Each Party hereby waives any forum non conveniens claim in connection with said arbitration or any similar objection or any claims against the enforcement of the arbitrator's ruling in any applicable jurisdiction. If a court of competent jurisdiction should find any part of this Agreement invalid, that provision will be omitted and the remainder of this Agreement remains in effect and be construed so as to best effectuate the original intent and purpose of this Agreement.
            </p>
            <p className="para">YOU HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. YOU AND WE ARE INSTEAD ELECTING THAT ALL DISPUTES, CLAIMS, OR REQUESTS FOR RELIEF SHALL BE RESOLVED BY ARBITRATION UNDER THIS ARBITRATION CLAUSE  ALL DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THESE TERMS MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER USER.</p>
            <p className="para"><span>Conflict of Provisions.</span> In the event that there exists a conflict between any term, condition or provision contained within these Terms, and in any term, condition, or provision contained within any other specific part or feature, the term, condition, or provision contained in such specific part or feature will control.
            </p>
            <p className="para"> <span>Severability.</span> If any part or parts of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such part or parts shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.</p>

            <p className="para"> <span>Entire Agreement.</span> These Terms of Use, our Privacy Policy and Cookies Policy constitute the sole and entire agreement between you and us with respect to the Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Site.
            </p>
          </div>

        </div>
      </section>
        <Footer />
    </>
  )
}

export default Termofuse
