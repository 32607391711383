import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Environment from '../../utils/Environment';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ContactDetail = ({ setCheckForms }) => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [telegram, setTelegram] = useState('');
  const [telegramErr, setTelegramErr] = useState('');
  const [kycName, setKycName] = useState('');
  const [kycBirth, setKycBirth] = useState('');
  const [kycNationality, setKycNationality] = useState('');
  const [kycAddress, setKycAddress] = useState('');
  const [kycNumber, setKycNumber] = useState('');
  const [nat, setNat] = useState('');
  const [kycCopy, setKycCopy] = useState('');
  const [kycCopyPreview, setKycCopyPreview] = useState('');
  const [startKyc, setStartKyc] = useState(false);
  const fileInputRef = useRef(null);

  const api_url = Environment.api_url2;
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('applyForm'));
    if (storedData && storedData.contactDetails) {
      setName(storedData.contactDetails.name || '');
      setEmail(storedData.contactDetails.email || '');
      setTelegram(storedData.contactDetails.telegram || '');
      setStartKyc(storedData.contactDetails.kyc || false);
    }
    window.scrollTo(0, 0);
  }, []);


  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  // const handleButtonClick = () => {
  //   if (name.length === 0) {
  //     setNameError('Please provide contact name');
  //   } else if (email.length === 0) {
  //     setEmailError('Please provide contact email address');
  //   } else if (!isValidEmail(email)) {
  //     setEmailError('Email is invalid');
  //   } else if (telegram.length === 0 ){
  //     setTelegramErr("Please provide contact telegram")
  //   } else {
  //     CreateForm();
  //   }
  // };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.includes('image') || file.type.includes('pdf')) {
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            setKycCopy(file);
            setKycCopyPreview(reader.result);
          } catch (error) {
            toast.error('The file format you provided is not supported, please add a PNG, JPG, JPEG, or PDF');
          }
        };
        reader.readAsDataURL(file);
      } else {
        toast.error('The file format you provided is not supported, please add a PNG, JPG, JPEG, or PDF');
      }
    }
  };
  const handleButtonClick = async () => {
    if (name.length === 0) {
      setNameError('Please provide contact name');
    } else if (email.length === 0) {
      setEmailError('Please provide contact email address');
    } else if (!isValidEmail(email)) {
      setEmailError('Email is invalid');
    } else if (telegram.length === 0) {
      setTelegramErr('Please provide contact telegram');
    } else {
      try {
        const response = await axios.get(`${api_url}/application/emailOrNameAlreadyExists`, {
          params: { email, name },
        });

        updateLocalStorage();

      } catch (error) {
        const errorMessage = error.response?.data?.message;
        const errorDetails = error.response?.data?.details?.[0];

        if (errorDetails) {
          if (errorDetails.name) {
            toast.error(errorDetails.name);
          } else if (errorDetails.email) {
            toast.error(errorDetails.email);
          } else {
            toast.error(errorMessage || 'An error occurred');
          }
        } else {
          toast.error(errorMessage || 'An error occurred');
        }
      }
    }
  };



  const updateLocalStorage = () => {
    let storedData = JSON.parse(localStorage.getItem('applyForm')) || {};

    storedData.contactDetails = {
      ...storedData.contactDetails,
      name: name,
      email: email,
      telegram: telegram,
      kyc: startKyc,
    };

    localStorage.setItem('applyForm', JSON.stringify(storedData));
    setCheckForms(1);
  }
  useEffect(() => {
    // Set initial local storage if not already set
    if (!localStorage.getItem('applyForm')) {
      const initialFormData = {
        contactDetails: {
          name: '',
          email: '',
          telegram: '',
          kyc: false,
        },
      };
      localStorage.setItem('applyForm', JSON.stringify(initialFormData));
    }
    window.scrollTo(0, 0);
  }, []);

  const CreateForm = async () => {
    const launchpadData = {
      contactDetails: {
        name: name,
        email: email,
        telegram: telegram,
        kyc: startKyc,
      }
    };
    // const config = {
    //   method: 'put',
    //   url: api_url + '/application',
    //   data: launchpadData,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };
    // try {
    //   await axios(config);
    localStorage.setItem('applyForm', JSON.stringify(launchpadData));
    setCheckForms(1);
    // } catch (error) {
    //   if (error?.response?.status === 401) {
    //     toast.error('Session expired, please login again to continue');
    //   } else {
    //     toast.error(error?.response?.data?.message);
    //     toast.error(error?.response?.data?.details?.[0]?.name);
    //     toast.error(error?.response?.data?.details?.[0]?.telegram);
    //   }
    // }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="contact-detail common-box-style">
      <div className="main-heading">
        <h6>CONTACT DETAILS</h6>
      </div>
      <div className="bottom-content">
        <div className="option-field">
          <div className="material-textfield">
            <input
              placeholder="Enter name"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError('');
              }}
            />
            <label>name</label>
          </div>
          {nameError && <p className="error-text">{nameError}</p>}
        </div>
        <div className="option-field">
          <div className="material-textfield">
            <input
              placeholder="Enter email address"
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError('');
              }}
            />
            <label>email address</label>
          </div>
          {emailError && <p className="error-text">{emailError}</p>}
        </div>
        <div className="option-field">
          <div className="material-textfield">
            <input
              placeholder="Enter Telegram username"
              type="text"
              value={telegram}
              onChange={(e) => { setTelegram(e.target.value); setTelegramErr('') }}
            />
            <label>telegram</label>
          </div>
          {telegramErr && <p className="error-text">{telegramErr}</p>}
        </div>
        {startKyc ? (
          <>
            <div className="kyc-parent">
              <h6 className="head-text">Personal Info (REQUIRED FOR KYC)</h6>
              <div className="option-field">
                <div className="material-textfield">
                  <input
                    placeholder="Enter Full Name"
                    type="text"
                    value={kycName}
                    onChange={(e) => setKycName(e.target.value)}
                  />
                  <label>Full Name</label>
                </div>

              </div>
              <div className="option-field">
                <div className="material-textfield">
                  <input
                    placeholder="Enter Date of Birth"
                    type="date"
                    value={kycBirth}
                    onChange={(e) => setKycBirth(e.target.value)}
                  />
                  <label>Date of Birth</label>
                  <img src="\assets\dopgrant\calendar-icon.svg" alt="img" className="img-fluid calendar-icon" />
                </div>
              </div>
              <div className="option-field">
                <div className="dropdown">
                  <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <label>{nat || "Nationality"}</label>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                      <path
                        d="M11.2264 5.5683H7.59221H4.31971C3.75971 5.5683 3.47971 6.24497 3.87638 6.64163L6.89805 9.6633C7.38221 10.1475 8.16971 10.1475 8.65388 9.6633L9.80305 8.51413L11.6755 6.64163C12.0664 6.24497 11.7864 5.5683 11.2264 5.5683Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a onClick={() => setNat('Afg')} className="dropdown-item" href="#">
                        Afghanistan
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setNat('China')} className="dropdown-item" href="#">
                        China
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setNat('Pak')} className="dropdown-item" href="#">
                        Pakistan
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setNat('Ind')} className="dropdown-item" href="#">
                        India{' '}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setNat('Isr')} className="dropdown-item" href="#">
                        Israel{' '}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setNat('USA')} className="dropdown-item" href="#">
                        USA{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="option-field">
                <div className="material-textfield">
                  <input
                    placeholder="Enter Current Address"
                    type="text"
                    value={kycAddress}
                    onChange={(e) => setKycAddress(e.target.value)}
                  />
                  <label>Current Address</label>
                </div>
              </div>
              <div className="option-field">
                <div className="material-textfield">
                  <input
                    placeholder="Enter Government-issued ID Number"
                    type="number"
                    value={kycNumber}
                    onChange={(e) => setKycNumber(e.target.value)}
                  />
                  <label>Government-issued ID Number</label>
                </div>
              </div>
              <div className="kyc-box">
                <h6>Please attach a copy of your government-issued ID</h6>
                <label htmlFor="upload" className="btn-startkyc btn-upload">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <path
                      d="M11.8389 16.7893H6.94891C3.26641 16.7893 1.69141 15.2143 1.69141 11.5318V11.4343C1.69141 8.10426 3.00391 6.49926 5.94391 6.22176C6.24391 6.19926 6.52891 6.42427 6.55891 6.73177C6.58891 7.03927 6.36391 7.31676 6.04891 7.34676C3.69391 7.56426 2.81641 8.67426 2.81641 11.4418V11.5393C2.81641 14.5918 3.89641 15.6718 6.94891 15.6718H11.8389C14.8914 15.6718 15.9714 14.5918 15.9714 11.5393V11.4418C15.9714 8.65926 15.0789 7.54926 12.6789 7.34676C12.3714 7.31676 12.1389 7.04677 12.1689 6.73927C12.1989 6.43177 12.4614 6.19926 12.7764 6.22926C15.7614 6.48426 17.0964 8.09677 17.0964 11.4493V11.5468C17.0964 15.2143 15.5214 16.7893 11.8389 16.7893Z"
                      fill="black"
                    />
                    <path
                      d="M9.39355 11.9144C9.08605 11.9144 8.83105 11.6594 8.83105 11.3519V2.81689C8.83105 2.50939 9.08605 2.25439 9.39355 2.25439C9.70105 2.25439 9.95605 2.50939 9.95605 2.81689V11.3519C9.95605 11.6594 9.70105 11.9144 9.39355 11.9144Z"
                      fill="black"
                    />
                    <path
                      d="M11.9059 5.05188C11.7634 5.05188 11.6209 4.99938 11.5084 4.88688L9.39344 2.77188L7.27844 4.88688C7.06094 5.10438 6.70094 5.10438 6.48344 4.88688C6.26594 4.66938 6.26594 4.30938 6.48344 4.09188L8.99594 1.57938C9.21344 1.36188 9.57344 1.36188 9.79094 1.57938L12.3034 4.09188C12.5209 4.30938 12.5209 4.66938 12.3034 4.88688C12.1984 4.99938 12.0484 5.05188 11.9059 5.05188Z"
                      fill="black"
                    />
                  </svg>
                  Upload
                </label>
                <input ref={fileInputRef} onChange={handleImageChange} type="file" className="d-none" id="upload" />
                {kycCopyPreview && (
                  <div className="after-upload">
                    <div className="left-side">
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                        <path
                          d="M11.8389 16.7893H6.94891C3.26641 16.7893 1.69141 15.2143 1.69141 11.5318V11.4343C1.69141 8.10426 3.00391 6.49926 5.94391 6.22176C6.24391 6.19926 6.52891 6.42427 6.55891 6.73177C6.58891 7.03927 6.36391 7.31676 6.04891 7.34676C3.69391 7.56426 2.81641 8.67426 2.81641 11.4418V11.5393C2.81641 14.5918 3.89641 15.6718 6.94891 15.6718H11.8389C14.8914 15.6718 15.9714 14.5918 15.9714 11.5393V11.4418C15.9714 8.65926 15.0789 7.54926 12.6789 7.34676C12.3714 7.31676 12.1389 7.04677 12.1689 6.73927C12.1989 6.43177 12.4614 6.19926 12.7764 6.22926C15.7614 6.48426 17.0964 8.09677 17.0964 11.4493V11.5468C17.0964 15.2143 15.5214 16.7893 11.8389 16.7893Z"
                          fill="black"
                        />
                        <path
                          d="M9.39355 11.9144C9.08605 11.9144 8.83105 11.6594 8.83105 11.3519V2.81689C8.83105 2.50939 9.08605 2.25439 9.39355 2.25439C9.70105 2.25439 9.95605 2.50939 9.95605 2.81689V11.3519C9.95605 11.6594 9.70105 11.9144 9.39355 11.9144Z"
                          fill="black"
                        />
                        <path
                          d="M11.9059 5.05188C11.7634 5.05188 11.6209 4.99938 11.5084 4.88688L9.39344 2.77188L7.27844 4.88688C7.06094 5.10438 6.70094 5.10438 6.48344 4.88688C6.26594 4.66938 6.26594 4.30938 6.48344 4.09188L8.99594 1.57938C9.21344 1.36188 9.57344 1.36188 9.79094 1.57938L12.3034 4.09188C12.5209 4.30938 12.5209 4.66938 12.3034 4.88688C12.1984 4.99938 12.0484 5.05188 11.9059 5.05188Z"
                          fill="black"
                        />
                      </svg>
                      <h6>{kycCopy.name}</h6>
                    </div>
                    <div className="right-side">
                      <a href="#" onClick={() => { setKycCopy(null); setKycCopyPreview(null); }} className="btn-delete">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                          <path
                            d="M15.9265 5.1521C15.9115 5.1521 15.889 5.1521 15.8665 5.1521C11.899 4.7546 7.93901 4.6046 4.01651 5.0021L2.48651 5.1521C2.17151 5.1821 1.89401 4.9571 1.86401 4.6421C1.83401 4.3271 2.05901 4.0571 2.36651 4.0271L3.89651 3.8771C7.88651 3.4721 11.929 3.6296 15.979 4.0271C16.2865 4.0571 16.5115 4.3346 16.4815 4.6421C16.459 4.9346 16.2115 5.1521 15.9265 5.1521Z"
                            fill="#FE0000"
                          />
                          <path
                            d="M6.55134 4.39449C6.52134 4.39449 6.49134 4.39449 6.45384 4.38699C6.15384 4.33449 5.94384 4.04199 5.99634 3.74199L6.16134 2.75949C6.28134 2.03949 6.44634 1.04199 8.19384 1.04199H10.1588C11.9138 1.04199 12.0788 2.07699 12.1913 2.76699L12.3563 3.74199C12.4088 4.04949 12.1988 4.34199 11.8988 4.38699C11.5913 4.43949 11.2988 4.22949 11.2538 3.92949L11.0888 2.95449C10.9838 2.30199 10.9613 2.17449 10.1663 2.17449H8.20134C7.40634 2.17449 7.39134 2.27949 7.27884 2.94699L7.10634 3.92199C7.06134 4.19949 6.82134 4.39449 6.55134 4.39449Z"
                            fill="#FE0000"
                          />
                          <path
                            d="M11.5836 17.1669H6.76857C4.15107 17.1669 4.04607 15.7194 3.96357 14.5494L3.47607 6.99693C3.45357 6.68943 3.69357 6.41943 4.00107 6.39693C4.31607 6.38193 4.57857 6.61443 4.60107 6.92193L5.08857 14.4744C5.17107 15.6144 5.20107 16.0419 6.76857 16.0419H11.5836C13.1586 16.0419 13.1886 15.6144 13.2636 14.4744L13.7511 6.92193C13.7736 6.61443 14.0436 6.38193 14.3511 6.39693C14.6586 6.41943 14.8986 6.68193 14.8761 6.99693L14.3886 14.5494C14.3061 15.7194 14.2011 17.1669 11.5836 17.1669Z"
                            fill="#FE0000"
                          />
                          <path
                            d="M10.4213 13.042H7.92383C7.61633 13.042 7.36133 12.787 7.36133 12.4795C7.36133 12.172 7.61633 11.917 7.92383 11.917H10.4213C10.7288 11.917 10.9838 12.172 10.9838 12.4795C10.9838 12.787 10.7288 13.042 10.4213 13.042Z"
                            fill="#FE0000"
                          />
                          <path
                            d="M11.0508 10.042H7.30078C6.99328 10.042 6.73828 9.78699 6.73828 9.47949C6.73828 9.17199 6.99328 8.91699 7.30078 8.91699H11.0508C11.3583 8.91699 11.6133 9.17199 11.6133 9.47949C11.6133 9.78699 11.3583 10.042 11.0508 10.042Z"
                            fill="#FE0000"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="kyc-parent">
            <h6 className="head-text">KYC</h6>
            <div className="kyc-box">
              <h6>Applicants are required to complete a KYC process</h6>
              <a href="https://in.sumsub.com/websdk/p/uni_Kq1ZIGtXwGtyovY5" target='_blank'>
                <button
                  // onClick={() => { setStartKyc(true) }}

                  className='btn-startkyc'>Start KYC</button>
              </a>
            </div>


          </div>
        )}
        <div className="bottom-btns">
          <button onClick={() => handleButtonClick()} className="btn-blackk">
            <h6>Next</h6>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M9.71387 6.03524L12.5472 8.86857L9.71387 11.7019M5.46387 6.03524L8.2972 8.86857L5.46387 11.7019" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};

export default ContactDetail;
