import React, { useEffect, useRef } from "react";
import "./dopcreators.scss";
import Footer from "../landing/footer/Footer";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import Navbar from "../landing/header/Navbar";

const Dopcreators = () => {
  const headingRef = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split('');


      ref.current.innerHTML = '';


      chars.forEach(char => {
        const charSpan = document.createElement('span');
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });


      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.1,
        duration: speed
      });

      gsap.to(ref.current.children, {
        color: '#fff',
        stagger: 0.1,
        duration: speed
      });
    };

    animateText(headingRef, 0.1);
    // animateText(btn1Ref, 0.1);  
  }, []);


  // different examples of 
  // const headingRef1 = useRef(null);
  // const headingRef2 = useRef(null);
  const paragraphRef1 = useRef(null);
  // const paragraphRef2 = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed, animationType) => {
      switch (animationType) {
        case 'fadeIn':
          gsap.from(ref.current, {
            opacity: 0,
            duration: speed
          });
          break;
        case 'slideUp':
          gsap.from(ref.current, {
            y: 100,
            opacity: 0,
            duration: speed
          });
          break;
        case 'scale':
          gsap.from(ref.current, {
            scale: 0,
            opacity: 0,
            duration: speed
          });
          break;
        default:
          break;
      }
    };

    // animateText(headingRef1, 0.5, 'fadeIn');
    // animateText(headingRef2, 1, 'slideUp');
    animateText(paragraphRef1, 2, 'scale');
    // animateText(paragraphRef2, 5, 'fadeIn');
  }, []);

  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);

  useEffect(() => {
    const isLargeScreen = window.matchMedia("(min-width: 1500px)").matches;
    const isMobileScreen = window.matchMedia("(max-width: 600px)").matches;

    gsap.to(firstSectionRef.current, {
      scrollTrigger: {
        trigger: secondSectionRef.current,
        start: isMobileScreen ? 'top 40%' : (isLargeScreen ? 'top 35%' : 'top 40%'),
        end: isMobileScreen ? 'bottom top' : (isLargeScreen ? 'bottom top' : 'bottom top'),
        scrub: true,
      },
      filter: 'blur(40px)',
    });
  }, []);


  return (
    <>
      <Navbar />
      <section className="dopcreator" ref={firstSectionRef}>
        <div className="custom-container">
          <div className="techbannerinner">
            <h1 className="techhead" ref={paragraphRef1}><span ref={headingRef}>DOP for creators</span></h1>
          </div>
        </div>
      </section>
      <section className="brand" ref={secondSectionRef}>
        <div className="dopphasesheader">
          <p className="dopphasesheaderpara">
            <span>01.</span>
            <span>BRAND ASSETS</span>
          </p>
        </div>
        <div className="custom-container">
          <div className="parenttextxx">
            <h2>Create engaging DOP content for your audience</h2>
            <h5>Download the official DOP</h5>
            <div className="parentinput">
              <a href="\dop_brand_assets.zip" className="btn-launch">
                <h6>Brand asset kit</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Dopcreators;
