import React, { useEffect, useRef, useState } from "react";
import "./blogs.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import gsap from "gsap";
import { Link } from "react-router-dom";
// import "./blog.scss";
import { ScrollTrigger } from "gsap/all";
// import Joinus from "../Tech/Joinus/Joinus";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
const Blogs = ({ secondSectionRef }) => {
  const headingRef = useRef(null);
  const [press, setallpress] = useState(null);
  const [presstop, setallpresstop] = useState();
  const [loading, setLoading] = useState(true);
  const [opens, setOpens] = useState(false);
  const Token = localStorage.getItem("AccessToken");
  const owl_option = {
    nav: true,
    dots: false,
    dotsEach: false,
    loop: true,
    autoplay: false,
    navText: [
      //   "<i class='fas fa-angle-left'></i>",
      //   "<i class='fas fa-angle-right'></i>",
    ],
    responsive: {
      0: {
        items: 1.1,
        margin: 9,
        loop: true,
      },
      361: {
        items: 1.2,
        margin: 9,
        loop: true,
      },
      600: {
        items: 1.2,
        margin: 9,
        loop: true,
      },
      700: {
        items: 3,
        margin: 9,
      },
      1000: {
        items: 4.2,
        margin: 9,
      },
      1200: {
        items: 4.2,
        margin: 9,
      },
    },
  };
  const btnRef = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split("");

      ref.current.innerHTML = "";
      chars.forEach((char) => {
        const charSpan = document.createElement("span");
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });

      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.03,
        duration: speed,
      });

      gsap.to(ref.current.children, {
        color: "#fff",
        stagger: 0.03,
        duration: speed,
      });
    };

    const btn = btnRef.current;

    const handleMouseEnter = () => {
      animateText(btnRef, 0.2);
    };

    const handleMouseLeave = () => {
      btnRef.current.innerHTML = btn.innerText;
    };

    btn.addEventListener("mouseenter", handleMouseEnter);
    btn.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      btn.removeEventListener("mouseenter", handleMouseEnter);
      btn.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);
  const AllPress = () => {
    setOpens(true);
    setLoading(true);
    let limit = 1000;
    axios
      .get(`https://blogs.dop.org/wp-json/wp/v2/posts?per_page=100`)
      .then((response) => {
        setOpens(false);
        // var arr = response?.data;
        // var theRemovedElement = arr.shift();
        setallpress(response?.data);
        setLoading(false);
        // setallpresstop(theRemovedElement)
      })
      .catch((err) => {
        setOpens(false);
        setLoading(false);
        toast.warning("Error", {
          position: "top-right",
          autoClose: 3000,
        });
        return false;
      });
  };
  useEffect(() => {
    AllPress();
  }, []);

  // var featuredBlogs = press?.slice(0, 6).map((elem, index) => {
  //   const str = elem?.title?.rendered?.replace(/\s/g, "-");
  //   const str2 = str?.toLowerCase();
  //   // console.log("we get values",str)
  //   const originalText = elem?.title.rendered;
  //   const correctedText = originalText?.replace(/&#8217;/g, "'");
  //   const correctedText2 = correctedText?.replace(/&#8216;/g, "'");
  //   const correctedText3 = correctedText2?.replace(/&#8211;/g, "-");
  //   const correctedText4 = correctedText3?.replace(/&#038;/g, "&");
  //   const correctedText5 = correctedText4?.replace(/&#8221;/g, '"');

  //   const orignalurl = str2;
  //   const correcturl = orignalurl?.replace(/&#8217;/g, "'");
  //   const correcturl1 = correcturl?.replace(/&#8216;/g, "'");
  //   const correcturl2 = correcturl1?.replace(/&#8211;/g, "-");
  //   const correcturl3 = correcturl2?.replace(/&#038;/g, "&");
  //   const correcturl4 = correcturl3?.replace(/&#8221;/g, '"');

  //   return (
  //     <>
  //       <Link to={`/blog`} className="w-100">
  //         <div class="item">
  //           <div className="mainimagessss">
  //             <img
  //               src={elem?.uagb_featured_image_src?.full[0]}
  //               alt="img"
  //               className="img-fluid innerimage"
  //             />
  //           </div>

  //           <h4> {elem?.date?.slice(0, 10).split("-").reverse().join("-")}</h4>
  //           <p>{correctedText5 ? correctedText5 : ""}</p>
  //         </div>
  //         {/* <div className="single-card blogcard">
  //           <div className="main-img">
  //             <img
  //               src={elem?.uagb_featured_image_src?.full[0]}
  //               alt="img"
  //               className="img-fluid"
  //             />
  //           </div>

  //           <div className="cardtexts">
  //             <p className="greypara featuredtext">[FEATURED]</p>
  //             <p className="greypara">
  //               {elem?.date?.slice(0, 10).split("-").reverse().join("-")}
  //             </p>
  //           </div>
  //           <p className="whitecardpara">
  //             {correctedText5 ? correctedText5 : ""}
  //           </p>
  //         </div> */}
  //       </Link>
  //     </>
  //   );
  // });

  const featuredBlogs = press?.slice(0, 6).map((elem, index) => {
    const str = elem?.title?.rendered?.replace(/\s/g, "-");
    const str2 = str?.toLowerCase();
    // console.log("we get values",str)
    const originalText = elem?.title.rendered;
    const correctedText = originalText?.replace(/&#8217;/g, "'");
    const correctedText2 = correctedText?.replace(/&#8216;/g, "'");
    const correctedText3 = correctedText2?.replace(/&#8211;/g, "-");
    const correctedText4 = correctedText3?.replace(/&#038;/g, "&");
    const correctedText5 = correctedText4?.replace(/&#8221;/g, '"');

    const orignalurl = str2;
    const correcturl = orignalurl?.replace(/&#8217;/g, "'");
    const correcturl1 = correcturl?.replace(/&#8216;/g, "'");
    const correcturl2 = correcturl1?.replace(/&#8211;/g, "-");
    const correcturl3 = correcturl2?.replace(/&#038;/g, "&");
    const correcturl4 = correcturl3?.replace(/&#8221;/g, '"');

    return (
      <>
        <Link to={`/blog/${elem.slug}`} className="w-100">
          <div class="item">
            <div className="mainimagessss">
              <img
                src={elem?.uagb_featured_image_src?.full[0]}
                alt="img"
                className="img-fluid innerimage"
              />
            </div>

            <h4> {elem?.date?.slice(0, 10).split("-").reverse().join("-")}</h4>
            <p>{correctedText5 ? correctedText5 : ""}</p>
          </div>
          {/* <div className="single-card blogcard">
            <div className="main-img">
              <img
                src={elem?.uagb_featured_image_src?.full[0]}
                alt="img"
                className="img-fluid"
              />
            </div>
  
            <div className="cardtexts">
              <p className="greypara featuredtext">[FEATURED]</p>
              <p className="greypara">
                {elem?.date?.slice(0, 10).split("-").reverse().join("-")}
              </p>
            </div>
            <p className="whitecardpara">
              {correctedText5 ? correctedText5 : ""}
            </p>
          </div> */}
        </Link>
      </>
    );
  });

  return (
    <>
      <section className="mainblog" ref={secondSectionRef}>
        <div className="dopphasesheader">
          <p className="dopphasesheaderpara">
            <span>01.</span>
            <span>BLOGS</span>
          </p>
          <Link to="/blog" className="btn-launch">
            <h6 ref={btnRef}>All Blogs</h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333"
                stroke="white"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </div>
        <div className="custom-container">
          <div className="blogsinner">
            <div className="mainhead">
              <h2>featured BLOGS</h2>
            </div>
          </div>
          <div className="mainparentcards">
            {featuredBlogs ? (
              <div class="owl_option">
                <OwlCarousel className="owl-theme" {...owl_option}>
                  {/* <div className="rightblog"> */}
                  {featuredBlogs?.length > 0 ? featuredBlogs : "no data here"}
                  {/* {loading ? (
                      <div className="set-loading-mid">
                        <div
                          className="spinner-border text-light"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <p style={{ color: "#fff" }}>Loading...</p>
                      </div>
                    ) : featuredBlogs?.length > 0 ? (
                      featuredBlogs
                    ) : (
                      <h6
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        No Data Here Right Now
                      </h6>
                    )} */}
                  {/* </div> */}
                  {/* <div class="item">
                    <div className="mainimagessss">
                      <img
                        src="\assets\Company\blogs.png"
                        alt="img"
                        className="img-fluid innerimage"
                      />
                    </div>

                    <h4>FEB 14, 2024</h4>
                    <p>
                      Member-Get-Member Program: advantages and benefits for
                      Bequant customers.
                    </p>
                  </div>
                  <div class="item">
                    <div className="mainimagessss">
                      <img
                        src="\assets\Company\blogs1.png"
                        alt="img"
                        className="img-fluid innerimage"
                      />
                    </div>

                    <h4>FEB 14, 2024</h4>
                    <p>
                    Member-Get-Member Program: advantages and benefits for Bequant customers.
                    </p>
                  </div>
                  <div class="item">
                    <div className="mainimagessss">
                      <img
                        src="\assets\Company\blogs2.png"
                        alt="img"
                        className="img-fluid innerimage"
                      />
                    </div>

                    <h4>FEB 14, 2024</h4>
                    <p>
                    Member-Get-Member Program: advantages and benefits for Bequant customers.
                    </p>
                  </div>
                  <div class="item">
                    <div className="mainimagessss">
                      <img
                        src="\assets\Company\blogs.png"
                        alt="img"
                        className="img-fluid innerimage"
                      />
                    </div>

                    <h4>FEB 14, 2024</h4>
                    <p>
                      Member-Get-Member Program: advantages and benefits for
                      Bequant customers.
                    </p>
                  </div>
                  <div class="item">
                    <div className="mainimagessss">
                      <img
                        src="\assets\Company\blogs2.png"
                        alt="img"
                        className="img-fluid innerimage"
                      />
                    </div>

                    <h4>FEB 14, 2024</h4>
                    <p>
                    Member-Get-Member Program: advantages and benefits for Bequant customers.
                    </p>
                  </div>
                  <div class="item">
                    <div className="mainimagessss">
                      <img
                        src="\assets\Company\blogs1.png"
                        alt="img"
                        className="img-fluid innerimage"
                      />
                    </div>

                    <h4>FEB 14, 2024</h4>
                    <p>
                    Member-Get-Member Program: advantages and benefits for Bequant customers.
                    </p>
                  </div> */}
                </OwlCarousel>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
